import React from "react";
import { Button, Col, Container, Form, Image, Row } from "react-bootstrap";
import profile from "../../assets/images/profile-img.png";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import signIn from "../../Firebase/auth/signin";

function SignIn() {
    const [email, setEmail] = React.useState('')
    const [password, setPassword] = React.useState('')
    const [error, setError] = React.useState(null)
    const navigate = useNavigate()

    const handleForm = async (event) => {
        event.preventDefault()

        const { result, error } = await signIn(email, password);

        if (error) {
            return setError("Incorrect account or password")
        }

        // else successful
        return navigate("/dashboard/product-list")
    }
    return (
        <>
            <Helmet>
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <title>Login To Admin Phu Nguyen</title>
            </Helmet>
            <Container className="sign-in-page">
                <div className="d-flex justify-content-center">
                    <Col xs={12} lg={6} className="">
                        <div className="card mb-4">
                            <div className="bg-soft">
                                <Row>
                                    <Col xs={7} className="d-center ">
                                        <div className="p-4 sign-in-page-text-header">
                                            <h5 className="">Welcome!</h5>
                                            <p>Sign in to continue to admin Phu Nguyen.</p>
                                        </div>
                                    </Col>
                                    <Col className="col-5 align-self-end">
                                        <Image src={profile} alt="" className="img-fluid" />
                                    </Col>
                                </Row>
                            </div>
                            <div className="form-sign-in">
                                <Form onSubmit={handleForm} className="form">
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control onChange={(e) => setEmail(e.target.value)} required type="email" name="email" id="email" placeholder="example@mail.com" />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control onChange={(e) => setPassword(e.target.value)} required type="password" name="password" id="password" placeholder="password" />
                                    </Form.Group>
                                    {error && <p>{error}</p>}
                                    <Button className="w-100" type="submit" size="lg">Sign In</Button>
                                </Form>
                            </div>
                        </div>
                        <div className="mt-5 text-center">
                        <p className="cl-495">
                        © {new Date().getFullYear()} Phu Nguyen Crafted with <i className="fa fa-heart"></i> by Upzoom
                        </p>
                    </div>
                    </Col>
                </div>
            </Container>
        </>
    );
}

export default SignIn;