import { ref, set } from "firebase/database";
import { db } from '../Firebase.config'

export default async function addContactRt(data){
    set(ref(db, 'contacts/' + data.id), {
        id: data.id,
        name: data.name,
        phone: data.phone,
        date: data.date,
        email: data.email,
        note: data.note,
        status: data.status
    });
}