import React, { useState } from 'react'
import { toast } from 'react-toastify';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage'
import { storage } from '../../../Firebase/Firebase.config';
import { Button, Card, Col, Container, Form, Image, ProgressBar, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ReactQuill from 'react-quill';
import Dropzone from "react-dropzone";
import addDataVi from '../../../Firebase/firestore/addDataVi';
import addDataUs from '../../../Firebase/firestore/addDataUs';

function AddProduct() {
    //state data
    const [selectedFiles, setselectedFiles] = useState([])
    const [selectedFilesImage, setselectedFilesImage] = useState([])
    const [idSlug, setIdSlug] = useState('')
    const [price, setPrice] = useState(0)
    const [title, setTitle] = useState('')
    const [selectCategory, setSelectCategory] = useState("")
    const [description, setDescription] = useState('')
    const [producer, setProducer] = useState('')
    const [origin, setOrigin] = useState('')
    const [priceUs, setPriceUs] = useState(0)
    const [titleUs, setTitleUs] = useState('')
    const [selectCategoryUs, setSelectCategoryUs] = useState(selectCategory)
    const [descriptionUs, setDescriptionUs] = useState('')
    const [producerUs, setProducerUs] = useState(producer)
    const [originUs, setOriginUs] = useState('')
    const options = [
        { value: "0", label: "Chọn Danh Mục" },
        { value: "1", label: "Thiết bị giải phẫu bệnh - pháp y" },
        { value: "2", label: "Thiết bị xét nghiệm, vi sinh" },
        { value: "3", label: "Thiết bị thăm dò chức năng và y học thể thao" },
        { value: "4", label: "Thiết bị chuẩn đoán hình ảnh – y học hạt nhân" },
        { value: "5", label: "Thiết bị phòng thí nghiệm y tế và công nghiệp" },
        { value: "6", label: "Dụng cụ phẫu thuật, Vật tư tiêu hao" },
    ]
    const optionsEnglish = [
        { value: "0", label: "Select Product" },
        { value: "1", label: "Medical and forensic pathology equipment" },
        { value: "2", label: "Laboratory equipment, microbiology" },
        { value: "3", label: "Functional Probes and Sports Medicine" },
        { value: "4", label: "Diagnostic imaging equipment – nuclear medicine" },
        { value: "5", label: "Medical and industrial laboratory equipment" },
        { value: "6", label: "Surgical Instruments, Consumables" },
    ]
    //upload image
    const handleAcceptedFiles = (files) => {
        files.map(file =>
        Object.assign(file, {
            preview: URL.createObjectURL(file),
            formattedSize: formatBytes(file.size),
        })
        )
        setselectedFiles(files)
    }
    const handleAcceptedFilesImage = (files) => {
        files.map(file =>
        Object.assign(file, {
            preview: URL.createObjectURL(file),
            formattedSize: formatBytes(file.size),
        })
        )
        setselectedFilesImage(files)
    }
    const formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
    }
    
    const [urls, setUrls] = useState([])
    const [progress, setProgress]= useState(0)
    const uploadFiles = (files) => {
        const promises = []
        files.map((file) => {
            const sotrageRef = ref(storage, `images/${file.name}`);
            const uploadTask = uploadBytesResumable(sotrageRef, file);
            promises.push(uploadTask)
            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    const prog = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                    setProgress(prog);
                },
                (error) => console.log(error),
                async () => {
                    await getDownloadURL(uploadTask.snapshot.ref).then((downloadURLs) => {
                        setUrls(prevState => [...prevState, downloadURLs])
                        console.log("File available at", downloadURLs);
                    });
                }
            );


        })
        Promise.all(promises)
            .then(() => toast('Đã tải hình ảnh lên', { hideProgressBar: true, autoClose: 2000, type: 'success' }))
            .then(err => console.log(err))

    };
    const handleSubmit = () => {
        uploadFiles(selectedFiles)
    }

    const [image, setImage] = useState('')
    const [progressImage, setProgressImage]= useState(0)
    const uploadFilesImage = (files) => {
        const promises = []
        files.map((file) => {
            const sotrageRef = ref(storage, `images/${file.name}`);
            const uploadTask = uploadBytesResumable(sotrageRef, file);
            promises.push(uploadTask)
            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    const prog = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                    setProgressImage(prog);
                },
                (error) => console.log(error),
                async () => {
                    await getDownloadURL(uploadTask.snapshot.ref).then((downloadURLs) => {
                        setImage(downloadURLs)
                        console.log("File available at", downloadURLs);
                    });
                }
            );


        })
        Promise.all(promises)
            .then(() => toast('Đã tải hình ảnh lên', { hideProgressBar: true, autoClose: 2000, type: 'success' }))
            .then(err => console.log(err))
    };
    const handleSubmitImage = () => {
        uploadFilesImage(selectedFilesImage)
    }


    // sử lý dữ liệu khi tải lên
    let categoryTitleVi = options.filter((category) => category.value === selectCategory)
    let categoryTitleUs = optionsEnglish.filter((category) => category.value === selectCategory)

    const dataVi = {
        id: idSlug,
        star: 5,
        producer: producer,
        origin: origin,
        categoryTitle: categoryTitleVi.length >= 1 && categoryTitleVi[0].label,
        category: selectCategory,
        image: image,
        price: parseInt(price),
        title: title,
        imageslide: urls,
        description: description,
    }

    const dataUs = {
        id: idSlug,
        star: 5,
        producer: producerUs,
        origin: originUs,
        categoryTitle: categoryTitleUs.length >= 1 && categoryTitleUs[0].label,
        category: selectCategoryUs,
        image: image,
        price: parseInt(priceUs),
        title: titleUs,
        imageslide: urls,
        description: descriptionUs,
    }

    const handleForm = () => {
        addDataVi(dataVi)
        .then(() => {
            addDataUs(dataUs)
            .then(() => {
                toast('Thêm Sản Phẩm Thành Công', { hideProgressBar: true, autoClose: 2000, type: 'success' })
                setselectedFiles([])
                setUrls([])
                setIdSlug("")
                setProducer("")
                setSelectCategory("")
                setselectedFilesImage([])
                setDescription("")
                setPrice(0)
                setOrigin("")
                setTitle("")
                setTitleUs("")
                setProducerUs("")
                setSelectCategoryUs("")
                setDescriptionUs("")
                setPriceUs(0)
                setProgress(0)
                setProgressImage(0)
                setOriginUs("")
                window.scrollTo({ top: 0, behavior: 'smooth' })
            })
            .catch(err => console.log(err))
        })
        .catch(err => console.log(err))
    }
    return (
        <div className="page-content">
        <Container fluid>
            <Row>
                <Col xs="12">
                    <Card>
                        <Card.Body>
                        <Card.Title>Thêm Sản Phẩm</Card.Title>
                        <p className="card-title-desc mb-4">
                            Điền đầy đủ các thông tin phía dưới
                        </p>
                        <Form>
                            <Row>
                                <Col xs={12} lg={12}>
                                    <div className="mb-3">
                                        <Form.Label htmlFor="productdesc">
                                            Image*
                                        </Form.Label>
                                        <Dropzone
                                        onDrop={acceptedFiles => {
                                            handleAcceptedFilesImage(acceptedFiles)
                                        }}
                                        maxFiles="1"
                                        >
                                        {({ getRootProps, getInputProps }) => (
                                            <div className="dropzone">
                                            <div
                                                className="dz-message needsclick"
                                                {...getRootProps()}
                                            >
                                                <Form.Control {...getInputProps()} />
                                                <div className="dz-message needsclick">
                                                <div className="mb-3">
                                                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                                                </div>
                                                <h4>Thả tập tin ở đây hoặc bấm vào để tải lên.</h4>
                                                </div>
                                            </div>
                                            </div>
                                        )}
                                        </Dropzone>
                                        <div className="dropzone-previews mt-3" id="file-previews">
                                        {selectedFilesImage.map((f, i) => {
                                            return (
                                            <Card
                                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                key={i + "-file"}
                                            >
                                                <div className="p-2">
                                                <Row className="align-items-center">
                                                    <Col className="col-auto">
                                                    <Image
                                                        data-dz-thumbnail=""
                                                        height="80"
                                                        className="avatar-sm rounded bg-light"
                                                        alt={f.name}
                                                        src={f.preview}
                                                    />
                                                    </Col>
                                                    <Col>
                                                    <Link
                                                        to="/"
                                                        className="text-muted font-weight-bold"
                                                    >
                                                        {f.name}
                                                    </Link>
                                                    <p className="mb-0">
                                                        <strong>{f.formattedSize}</strong>
                                                    </p>
                                                    </Col>
                                                </Row>
                                                </div>
                                            </Card>
                                            )
                                        })}
                                        {progressImage > 0 && <ProgressBar animated variant="success" now={progressImage} label={`${progressImage}%`} /> }
                                        <div className="d-flex justify-content-end">
                                            <Button type="button" variant="danger" className="btn mt-3" onClick={handleSubmitImage}>Update Image Now!</Button>
                                        </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} lg={12}>
                                    <div className="mb-3">
                                        <Form.Label htmlFor="productdesc">
                                        List Ảnh* (List ảnh hiển thị trên trang chi tiết sản phẩm)
                                        </Form.Label>
                                        <Dropzone
                                        onDrop={acceptedFiles => {
                                            handleAcceptedFiles(acceptedFiles)
                                        }}
                                        >
                                        {({ getRootProps, getInputProps }) => (
                                            <div className="dropzone">
                                            <div
                                                className="dz-message needsclick"
                                                {...getRootProps()}
                                            >
                                                <Form.Control {...getInputProps()} />
                                                <div className="dz-message needsclick">
                                                <div className="mb-3">
                                                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                                                </div>
                                                <h4>Thả tập tin ở đây hoặc bấm vào để tải lên.</h4>
                                                </div>
                                            </div>
                                            </div>
                                        )}
                                        </Dropzone>
                                        <div className="dropzone-previews mt-3" id="file-previews">
                                        {selectedFiles.map((f, i) => {
                                            return (
                                            <Card
                                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                key={i + "-file"}
                                            >
                                                <div className="p-2">
                                                <Row className="align-items-center">
                                                    <Col className="col-auto">
                                                    <Image
                                                        data-dz-thumbnail=""
                                                        height="80"
                                                        className="avatar-sm rounded bg-light"
                                                        alt={f.name}
                                                        src={f.preview}
                                                    />
                                                    </Col>
                                                    <Col>
                                                    <Link
                                                        to="/"
                                                        className="text-muted font-weight-bold"
                                                    >
                                                        {f.name}
                                                    </Link>
                                                    <p className="mb-0">
                                                        <strong>{f.formattedSize}</strong>
                                                    </p>
                                                    </Col>
                                                </Row>
                                                </div>
                                            </Card>
                                            )
                                        })}
                                        {progress > 0 && <ProgressBar animated variant="success" now={progress} label={`${progress}%`} /> }
                                        <span className="text-danger">Lưu ý cần upload hình ảnh trước khi thêm sản phẩm.</span>
                                        <div className="d-flex justify-content-end">
                                            <Button type="button" variant="danger" className="btn mt-3" onClick={handleSubmit}>Update List Image Now!</Button>
                                        </div>
                                        </div>
                                    </div>
                                    </Col>
                                    <Col sm={12} lg={12}>
                                        <div className="mb-3">
                                            <Form.Label htmlFor="metatitle">ID*</Form.Label>
                                            <Form.Control
                                                required
                                                id="metaslug"
                                                type="text"
                                                
                                                onChange={(e) => setIdSlug(e.target.value)} value={idSlug}
                                                className="form-control"
                                                placeholder="Ví dụ: may-cat-tieu-ban-ban-tu-dong-m530 *Không được trùng tên với các sản phẩm khác(đây là id của sản phẩm)"
                                            />
                                        </div>
                                    </Col>
                                    <Col sm={12}>
                                        <Form.Label className='text-danger'>
                                            Dữ Liệu Sản Phẩm Tiếng Việt
                                        </Form.Label>
                                    </Col>
                                    <Col xs={12} lg={6}>
                                        <div className="mb-3">
                                            <Form.Label htmlFor="productname">Tên Sản Phẩm*</Form.Label>
                                            <Form.Control
                                                required
                                                id="tourname"
                                                type="text"
                                                onChange={(e) => setTitle(e.target.value)} value={title}
                                                className="form-control"
                                                placeholder="Tên Sản Phẩm"
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} lg={6}>
                                        <div className="mb-3">
                                            <Form.Label htmlFor="manufacturername">
                                                Giá Sảm Phẩm (VNĐ)*
                                            </Form.Label>
                                            <Form.Control
                                                required
                                                id="pricetour"
                                                type="number"
                                                onChange={(e) => setPrice(e.target.value)} value={price}
                                                className="form-control"
                                                placeholder="Giá Sảm Phẩm (VNĐ)"
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} lg={4}>
                                        <div className="mb-3">
                                            <Form.Label className="control-label">Nhà Sản Xuất*</Form.Label>
                                            <Form.Control
                                                required
                                                id="tourname"
                                                type="text"
                                                onChange={(e) => setProducer(e.target.value)} value={producer}
                                                className="form-control"
                                                placeholder="Nhà Sản Xuất"
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} lg={4}>
                                        <div className="mb-3">
                                            <Form.Label className="control-label">Xuất Xứ*</Form.Label>
                                            <Form.Control
                                                required
                                                id="tourname"
                                                type="text"
                                                onChange={(e) => setOrigin(e.target.value)} value={origin}
                                                className="form-control"
                                                placeholder="Xuất Xứ"
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} lg={4}>
                                        <div className="mb-3">
                                            <Form.Label className="control-label">Danh Mục*</Form.Label>
                                            <Form.Select required className="form-control select2" onChange={(e) => setSelectCategory(e.target.value)} value={selectCategory}>
                                            {options.map((item ,i)=>{
                                                return <option key={i} value={item.value}>{item.label}</option>
                                            })}
                                            </Form.Select>
                                        </div>
                                    </Col>
                                    <Col xs={12} lg={12}>
                                        <div className="mb-3">
                                            <Form.Label htmlFor="productdesc">
                                                Thông Tin*
                                            </Form.Label>
                                            <ReactQuill theme="snow"  value={description} onChange={setDescription} />
                                        </div>
                                    </Col>
                                    <Col sm={12}>
                                        <Form.Label className='text-danger'>
                                            Product Data English
                                        </Form.Label>
                                    </Col>
                                    <Col xs={12} lg={6}>
                                        <div className="mb-3">
                                            <Form.Label htmlFor="productname">Product Title*</Form.Label>
                                            <Form.Control
                                                required
                                                id="tourname"
                                                type="text"
                                                onChange={(e) => setTitleUs(e.target.value)} value={titleUs}
                                                className="form-control"
                                                placeholder="Product Title"
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} lg={6}>
                                        <div className="mb-3">
                                            <Form.Label htmlFor="manufacturername">
                                                Price (USD)*
                                            </Form.Label>
                                            <Form.Control
                                                required
                                                id="pricetour"
                                                type="number"
                                                onChange={(e) => setPriceUs(e.target.value)} value={priceUs}
                                                className="form-control"
                                                placeholder="Price (USD)"
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} lg={4}>
                                        <div className="mb-3">
                                            <Form.Label className="control-label">Producer*</Form.Label>
                                            <Form.Control
                                                required
                                                id="tourname"
                                                type="text"
                                                onChange={(e) => setProducerUs(e.target.value)} value={producerUs}
                                                className="form-control"
                                                placeholder="Producer"
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} lg={4}>
                                        <div className="mb-3">
                                            <Form.Label className="control-label">Origin*</Form.Label>
                                            <Form.Control
                                                required
                                                id="tourname"
                                                type="text"
                                                onChange={(e) => setOriginUs(e.target.value)} value={originUs}
                                                className="form-control"
                                                placeholder="Origin"
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} lg={4}>
                                        <div className="mb-3">
                                            <Form.Label className="control-label">Category*</Form.Label>
                                            <Form.Select required className="form-control select2" defaultValue={selectCategory} onChange={(e) => setSelectCategoryUs(e.target.value)} value={selectCategoryUs}>
                                            {optionsEnglish.map((item ,i)=>{
                                                return <option key={i} value={item.value}>{item.label}</option>
                                            })}
                                            </Form.Select>
                                        </div>
                                    </Col>
                                    <Col xs={12} lg={12}>
                                        <div className="mb-3">
                                            <Form.Label htmlFor="productdesc">
                                                Information*
                                            </Form.Label>
                                            <ReactQuill theme="snow"  value={descriptionUs} onChange={setDescriptionUs} />
                                        </div>
                                    </Col>
                                </Row>
                                <div className="d-flex flex-wrap gap-2">
                                {urls.lenght === 0 || idSlug === "" ? <Button type="button" disabled variant="secondary" className="btn" >
                                    Thêm Sản Phẩm
                                </Button> : <Button type="button" onClick={handleForm} variant="primary" className="btn" >
                                    Thêm Sản Phẩm
                                </Button>}
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    </div>      
    )
}

export default AddProduct