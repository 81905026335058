import React, { useEffect, useState } from 'react'
import { getDatabase, ref, child, get } from "firebase/database";
import { useParams } from 'react-router-dom';
import UpdateProduct from './UpdateProduct';
function UpdateProductPage() {
        let {id} = useParams()
        const [dataVi, setDataVi] = useState(null)
        const [dataUs, setDataUs] = useState(null)
    
        useEffect(() => {
            const dbRef = ref(getDatabase());
            get(child(dbRef, `product/vi/${id}`)).then((snapshot) => {
            if (snapshot.exists()) {
                const data = snapshot.val();
                setDataVi(data)
            } else {
                console.log("No data available");
            }
            }).catch((error) => {
            console.error(error);
            })
            const dbRefs = ref(getDatabase());
            get(child(dbRefs, `product/us/${id}`)).then((snapshot) => {
            if (snapshot.exists()) {
                const data = snapshot.val();
                setDataUs(data)
            } else {
                console.log("No data available");
            }
            }).catch((error) => {
            console.error(error);
            })
        },[])

        console.log(dataVi)

    return (
        dataVi && dataUs && <UpdateProduct productVi={dataVi} productUs={dataUs} />
    )
}

export default UpdateProductPage