"use client"
import React from "react";
import SidebarContent from "./SidebarContent";
import logo from "../../assets/images/logo.png";
import logosm from "../../assets/images/loader.png";

import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";

const Sidebar = props => {

  return (
    <>
      <div className="vertical-menu">
        <div className="navbar-brand-box">
        <div className="logo">
          <Link to="/" >
            <div className="logo-sm">
              <Image src={logosm} alt="" height="26" />
            </div>
          </Link>
          <Link to="/" >
            <div className="logo-lg">
              <Image src={logo} alt="" height="52" />
            </div>
          </Link>
        </div>
        </div>
        <div data-simplebar className="h-100">
          <SidebarContent />
        </div>
        <div className="sidebar-background"></div>
      </div>
    </>
  );
};

export default Sidebar 
