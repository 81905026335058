import React, { useEffect, useMemo, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { getDatabase, ref, child, get } from "firebase/database";
import { filter, orderBy } from 'lodash'
import SingleProductAdmin from '../../../components/SingleProductAdmin/SingleProductAdmin';
import Pagination from '../../../components/Pagination';

function includes(a, b) {
    return a.toLowerCase().includes(b.toLowerCase())
    }
    function check(data, typing) {
        // console.log(book)
        return (
            includes(data.title || '', typing) ||
            includes(data.categoryTitle || '', typing) ||
            includes(data.producer || '', typing) ||
            includes(data.origin || '', typing)
        )
    }

function ListProduct() {
    const [typing, setTyping] = useState('')
    const [items, setItems] = useState([])
    const [data, setData] = useState([])
    const [currentPage, setCurrentPage] = useState(1)

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
    useEffect(() => {
    const dbRef = ref(getDatabase());
    get(child(dbRef, `product/vi`)).then((snapshot) => {
        if (snapshot.exists()) {
        const data = snapshot.val();
        setData(Object.values(data))
        } else {
        console.log("No data available");
        }
    }).catch((error) => {
        console.error(error);
    })
    },[])

    useEffect(() => {
        if (data.length >= 1) {
            if (typing.length < 2 && data.length > 0) {
                setItems(data)
                setCurrentPage(1)
            }
            if (typing.length >= 2 && data.length > 0) {
                    const result = filter(data, blog => check(blog, typing))
                    setItems(orderBy(result))
                    setCurrentPage(1)
            }
        }
    }, [typing, data])

    let PageSize = 12
  
    const currentTableData = useMemo(() => {
      const firstPageIndex = (currentPage - 1) * PageSize
      const lastPageIndex = firstPageIndex + PageSize
      return items.slice(firstPageIndex, lastPageIndex)
    }, [currentPage, items])
  
    const onChangeClickPage = (page) => {
      setCurrentPage(page)
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }

    return (
        <div className="page-content">
            <div className='d-flex mb-4'>
                <div className='mr-auto d-flex align-items-center'>
                <h5 className='mb-0'>Danh Sách Sản Phẩm</h5>
                </div>
                <div className='d-flex'>
                <form className="app-search mr-3 d-none d-lg-block">
                    <div className="position-relative">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Tìm kiếm tên sản phẩm, danh mục, hãng sản xuất, nước sản xuất..."
                        value={typing}
                        onChange={e => setTyping(e.target.value)}
                    />
                    <span className="bx bx-search-alt" />
                    </div>
                </form>
                </div>
            </div>
            <form className="app-search mr-3 d-blog d-lg-none">
                <div className="position-relative">
                <input
                    type="text"
                    className="form-control"
                    placeholder="Tìm kiếm tên sản phẩm, danh mục, hãng sản xuất, nước sản xuất..."
                    value={typing}
                    onChange={e => setTyping(e.target.value)}
                />
                <span className="bx bx-search-alt" />
                </div>
            </form>
            <Row>
                {currentTableData.map((item, index) => {
                return <Col xl={4} lg={4} key={index} className="animated fadeInUp mb-3">
                    <SingleProductAdmin data={item} handleShow={handleShow} handleClose={handleClose} show={show} setShow={setShow}/>
                </Col>
                })}
                <Pagination
                    className='pagination-bar'
                    currentPage={currentPage}
                    totalCount={items.length}
                    pageSize={PageSize}
                    onPageChange={(page) => onChangeClickPage(page)}
                />
            </Row>
        </div>
    )
}

export default ListProduct