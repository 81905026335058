import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {FaRegCalendarCheck, FaUser} from "react-icons/fa";

const BlogFirstArticle = ({ blog, i }) => {
  const { t } = useTranslation();
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  const formattedDate = new Intl.DateTimeFormat('en-US', options).format(blog.date);
  return (
    <>
      <article className="postbox post format-image mb-40" key={i}>
        <div className="postbox__thumb">
          <Link to={`/blog/${blog.id}`}>
            <img src={blog.image} alt="blog img" />
          </Link>
        </div>
        <div className="postbox__text p-50">
          <div className="post-meta mb-15">
            <span>
              <FaRegCalendarCheck className="mtup-5px"/> {formattedDate}
            </span>
            <span>
              <Link to={`/blog/${blog.id}`}>
              <FaUser className="mtup-5px"/> {" "} {blog.author}
              </Link>
            </span>
            {/* <span>
              <Link to="/blogDetails">
                <i className="far fa-comments"></i> 23 Comments
              </Link>
            </span> */}
          </div>
          <h3 className="blog-title">
            <Link to={`/blog/${blog.id}`}>{blog.title}</Link>
          </h3>
          <div className="post-text mb-20">
            <div dangerouslySetInnerHTML={{
                  __html: blog.description.slice(0, 280) + "..."
                }}></div>
          </div>
          <div className="read-more mt-30">
            <Link to={`/blog/${blog.id}`} className="primary_btn theme-btn">
              {t("blog.readmore")}
            </Link>
          </div>
        </div>
      </article>
    </>
  );
};

export default BlogFirstArticle;
