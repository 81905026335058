import React from "react";
import { useTranslation } from "react-i18next";

const ShopDetailsDesc = ({ product }) => {
  const { t } = useTranslation();
  return (
    <>
      <section className="product-desc-area pb-80">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="bakix-details-tab">
                <ul
                  className="nav text-center justify-content-start pb-30 mb-50"
                  id="myTab"
                  role="tablist"
                >
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="desc-tab"
                      data-bs-toggle="tab"
                      href="#id-desc"
                      role="tab"
                      aria-controls="home"
                      aria-selected="true"
                    >
                      {t("header.describe")}
                    </a>
                  </li>
                </ul>
              </div>
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="id-desc"
                  role="tabpanel"
                  aria-labelledby="desc-tab"
                >
                  <div className="event-text mt-5">
                    <div
                      className=""
                      dangerouslySetInnerHTML={{
                        __html: product.description,
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ShopDetailsDesc;
