
import React from "react";
import { Link } from "react-router-dom";
import SimpleBar from "simplebar-react";


const SidebarContent = props => {

  return (
    <>
      <SimpleBar className="h-100">
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">Menu</li>
            <li>
              <Link to="/">
                  <i className="bx bx-home-heart"></i>
                  <span>Trang Chủ</span>
              </Link>
            </li>
            <li>
              <Link to="/dashboard/product-list" >
                  <i className='bx bx-list-ul' ></i>
                  <span>Danh Sách Sản Phẩm</span>
              </Link>
            </li>
            <li>
              <Link to="/dashboard/add-product">
                  <i className='bx bx-list-plus' ></i>
                  <span>Thêm Sản Phẩm</span>
              </Link>
            </li>
            <li>
              <Link to="/dashboard/blog-list">
                  <i className='bx bxl-blogger' ></i>
                  <span>Danh Sách Blog</span>
              </Link>
            </li>
            <li>
              <Link to="/dashboard/add-blog">
                  <i className='bx bx-book-add' ></i>
                  <span>Thêm Blog</span>
              </Link>
            </li>
            <li>
              <Link to="/dashboard/contact-list">
                  <i className='bx bx-book-add' ></i>
                  <span>Danh Sách Liên Hệ</span>
              </Link>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </>
  );
};


export default SidebarContent;
