import React from "react";
import { useTranslation } from "react-i18next";

const HomeAboutArea = () => {
  const { t } = useTranslation();
  return (
    <>
      <section className="about-area pt-120 pb-90">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-5 about_left">
              <div className="medical-icon-brand-2">
                <img src="img/about/medical-brand-icon-border.png" alt="" />
              </div>
              <div className="about-left-side pos-rel mb-30">
                <div className="about-front-img">
                  <img src="img/about/about-img.jpg" alt="" />
                </div>
                <div className="about-shape">
                  <img src="img/about/about-shape.png" alt="" />
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-7">
              <div className="about-right-side mb-30">
                <div className="about-title mb-20">
                  <h5>{t("header.about")}</h5>
                  <h1>{t("aboutus.aboutCompany")}</h1>
                </div>
                <div className="about-text">
                  <p>{t("aboutus.aboutContent")}</p>
                  <p>{t("aboutus.aboutContent2")}</p>
                  <br />
                </div>
                {/* <div className="about-author d-flex align-items-center">
                           <div className="author-ava">
                              <img src="img/about/author-ava.png" alt=""/>
                           </div>
                           <div className="author-desination">
                              <h4>Rosalina D. Williamson</h4>
                              <h6>founder</h6>
                           </div>
                        </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeAboutArea;
