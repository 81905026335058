import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import CommonPageHeader from "../../../components/CommonPageHeader/CommonPageHeader";
import HomeFooter from "../../Home/Home/HomeFooter/HomeFooter";
import HomeHeader from "../../Home/Home/HomeHeader/HomeHeader";
import ShopBanner from "./ShopBanner/ShopBanner";
import { useLocation} from 'react-router-dom';
import queryString from 'query-string'
import ScrollTotop from "../../../components/ScrollToTop";
import { getDatabase, ref, child, get } from "firebase/database";
import { Spinner } from "react-bootstrap";

const ShopPage = () => {
  const { t, i18n } = useTranslation();
  let location = useLocation();
  const { product, producer } = queryString.parse(location.search)

  const [data, setData] = useState(null)
  
  useEffect(() => {
    const dbRef = ref(getDatabase());
    get(child(dbRef, `product/${i18n.language}`)).then((snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        setData(Object.values(data))
      } else {
        console.log("No data available");
      }
    }).catch((error) => {
      console.error(error);
    })
  },[i18n.language])

  return (
    <>
      <Helmet>
        <title>{t("shoppage.title")}</title>
        <link rel="canonical" href="https://www.tacobell.com/" />
        <meta property="og:title" content={t("shoppage.title")} />
      </Helmet>
      <ScrollTotop />
      <HomeHeader />
      <CommonPageHeader
        title={t("shoppage.shoppage")}
        subtitle={t("shoppage.shop")}
      />
      {data ? <ShopBanner productId={product} producerId={producer} data={data}/> 
      :<div className="pt-80 pb-80 text-center">
        <Spinner animation="grow" variant="primary" />
        <Spinner animation="grow" variant="secondary" />
        <Spinner animation="grow" variant="success" />
        <Spinner animation="grow" variant="danger" />
        <Spinner animation="grow" variant="warning" />
        <Spinner animation="grow" variant="info" />
        <Spinner animation="grow" variant="light" />
        <Spinner animation="grow" variant="dark" />
        </div>}
      <HomeFooter />
    </>
  );
};

export default ShopPage;
