import React, { useMemo, useState } from 'react'
import { toast } from 'react-toastify';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage'
import { storage } from '../../../Firebase/Firebase.config';
import { Button, Card, Col, Container, Form, Image, ProgressBar, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ReactQuill, { Quill } from "react-quill";
import Dropzone from "react-dropzone";
import ImageUploader from "quill-image-uploader";
import addBlogVi from '../../../Firebase/firestore/addBlogVi';
import addBlogUs from '../../../Firebase/firestore/addBlogUs';

// #2 register module
Quill.register("modules/imageUploader", ImageUploader);

function UpdateBlog({blogVi, blogUs}) {
    //state data
    const [selectedFiles, setselectedFiles] = useState([])
    const [idSlug, setIdSlug] = useState(blogVi.id)
    const [title, setTitle] = useState(blogVi.title)
    const [image, setImage] = useState(blogVi.image)
    const [description, setDescription] = useState(blogVi.description)
    const [titleUs, setTitleUs] = useState(blogUs.title)
    const [descriptionUs, setDescriptionUs] = useState(blogUs.description)

    
    const modules = useMemo(
        () => ({
            toolbar: [
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                ["bold", "italic", "underline", "strike", "blockquote"],
                [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
                [
                    { list: "ordered" },
                    { list: "bullet" },
                    { indent: "-1" },
                    { indent: "+1" }
                ],
                ["link", "image"],
                ["clean"]
                ],
                // # 4 Add module and upload function
                imageUploader: {
                upload: (file) => {
                    return new Promise((resolve, reject) => {
                    const formData = new FormData();
                    formData.append("image", file);
                    const sotrageRef = ref(storage, `blogs/${file.name}`);
                    const uploadTask = uploadBytesResumable(sotrageRef, file);
                    uploadTask.on(
                        "state_changed",
                        (snapshot) => {
                            const prog = Math.round(
                                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                            );
                        },
                        (error) => console.log(error),
                        async () => {
                            await getDownloadURL(uploadTask.snapshot.ref).then((downloadURLs) => {
                                resolve(downloadURLs);
                                console.log("File available at", downloadURLs);
                            });
                        }
                    )
                    
                    });
                }
                }
            }),
            []
        );
        const formats = [
            'header',
            'bold', 'italic', 'underline', 'strike', 'blockquote',
            'list', 'bullet', 'indent',
            'link', 'image', 'align'
        ]
    
    //upload image
    const handleAcceptedFiles = (files) => {
        files.map(file =>
        Object.assign(file, {
            preview: URL.createObjectURL(file),
            formattedSize: formatBytes(file.size),
        })
        )
        setselectedFiles(files)
    }
    const formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
    }
    
    const [urls, setUrls] = useState([])
    const [progress, setProgress]= useState(0)
    const uploadFiles = (files) => {
        const promises = []
        files.map((file) => {
            const sotrageRef = ref(storage, `images/${file.name}`);
            const uploadTask = uploadBytesResumable(sotrageRef, file);
            promises.push(uploadTask)
            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    const prog = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                    setProgress(prog);
                },
                (error) => console.log(error),
                async () => {
                    await getDownloadURL(uploadTask.snapshot.ref).then((downloadURLs) => {
                        setUrls(prevState => [...prevState, downloadURLs])
                        console.log("File available at", downloadURLs);
                    });
                }
            );


        })
        Promise.all(promises)
            .then(() => toast('Đã tải hình ảnh lên', { hideProgressBar: true, autoClose: 2000, type: 'success' }))
            .then(err => console.log(err))

    };
    const handleSubmit = () => {
        uploadFiles(selectedFiles)
    }
    

    const dataVi = {
        id: idSlug,
        date: Date.now(),
        author: "Phú Nguyên",
        image: urls.length >=1 ? urls[0] : image,
        title: title,
        description: description,
    }

    const dataUs = {
        id: idSlug,
        date: Date.now(),
        author: "Phu Nguyen",
        image: urls.length >=1 ? urls[0] : image,
        title: titleUs,
        description: descriptionUs,
    }


    const handleForm = () => {
        addBlogVi(dataVi)
        .then(() => {
            addBlogUs(dataUs)
            .then(() => {
                toast('Update Blog Thành Công', { hideProgressBar: true, autoClose: 2000, type: 'success' })
                setselectedFiles([])
                setUrls([])
                setIdSlug("")
                setDescription("")
                setTitleUs("")
                setDescriptionUs("")
                window.location.reload(true);
            })
            .catch(err => console.log(err))
        })
        .catch(err => console.log(err))
    }
    return (
        <div className="page-content">
        <Container fluid>
            <Row>
                <Col xs="12">
                    <Card>
                        <Card.Body>
                        <Card.Title>Cập Nhập Blog</Card.Title>
                        <Form>
                            <Row>
                                <Col xs={12} lg={12}>
                                    <div className="mb-3">
                                        <Form.Label htmlFor="productdesc">
                                            Ảnh Nền (Chỉ nhận 1 hình ảnh)
                                        </Form.Label>
                                        <Dropzone
                                        onDrop={acceptedFiles => {
                                            handleAcceptedFiles(acceptedFiles)
                                        }}
                                        maxFiles="1"
                                        >
                                        {({ getRootProps, getInputProps }) => (
                                            <div className="dropzone">
                                            <div
                                                className="dz-message needsclick"
                                                {...getRootProps()}
                                            >
                                                <Form.Control {...getInputProps()} />
                                                <div className="dz-message needsclick">
                                                <div className="mb-3">
                                                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                                                </div>
                                                <h4>Thả tập tin ở đây hoặc bấm vào để tải lên.</h4>
                                                </div>
                                            </div>
                                            </div>
                                        )}
                                        </Dropzone>
                                        <div className="dropzone-previews mt-3" id="file-previews">
                                        {selectedFiles.map((f, i) => {
                                            return (
                                            <Card
                                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                key={i + "-file"}
                                            >
                                                <div className="p-2">
                                                <Row className="align-items-center">
                                                    <Col className="col-auto">
                                                    <Image
                                                        data-dz-thumbnail=""
                                                        height="80"
                                                        className="avatar-sm rounded bg-light"
                                                        alt={f.name}
                                                        src={f.preview}
                                                    />
                                                    </Col>
                                                    <Col>
                                                    <Link
                                                        to="/"
                                                        className="text-muted font-weight-bold"
                                                    >
                                                        {f.name}
                                                    </Link>
                                                    <p className="mb-0">
                                                        <strong>{f.formattedSize}</strong>
                                                    </p>
                                                    </Col>
                                                </Row>
                                                </div>
                                            </Card>
                                            )
                                        })}
                                        {progress > 0 && <ProgressBar animated variant="success" now={progress} label={`${progress}%`} /> }
                                        <span className="text-danger">Lưu ý đã chọn hình ảnh phải update trước khi cập nhập Blog.(Thay thế ảnh hiện có)</span>
                                        <div className="d-flex justify-content-end">
                                            <Button type="button" variant="danger" className="btn mt-3" onClick={handleSubmit}>Update Image Now!</Button>
                                        </div>
                                        </div>
                                    </div>
                                    </Col>
                                    <Col sm={12} lg={12}>
                                        <div className="mb-3">
                                            <Form.Label htmlFor="metatitle">ID*</Form.Label>
                                            <Form.Control
                                                required
                                                id="metaslug"
                                                type="text"
                                                
                                                onChange={(e) => setIdSlug(e.target.value)} value={idSlug}
                                                className="form-control"
                                                placeholder="Ví dụ: hoi-thao-khoa-hoc-giai-phau-benh-te-bao-hoc-viet-nam-lan-10 *Không được trùng tên với các blog khác(đây là id của blog)"
                                            />
                                        </div>
                                    </Col>
                                    <Col sm={12}>
                                        <Form.Label className='text-danger'>
                                            Dữ Liệu Sản Phẩm Tiếng Việt
                                        </Form.Label>
                                    </Col>
                                    <Col xs={12}>
                                        <div className="mb-3">
                                            <Form.Label htmlFor="productname">Tên Blog*</Form.Label>
                                            <Form.Control
                                                required
                                                id="tourname"
                                                type="text"
                                                onChange={(e) => setTitle(e.target.value)} value={title}
                                                className="form-control"
                                                placeholder="Tên Blog"
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} lg={12}>
                                        <div className="mb-3">
                                            <Form.Label htmlFor="productdesc">
                                                Thông Tin*
                                            </Form.Label>
                                            <ReactQuill theme="snow" modules={modules} formats={formats} value={description} onChange={setDescription} />
                                        </div>
                                    </Col>
                                    <Col sm={12}>
                                        <Form.Label className='text-danger'>
                                            Product Data English
                                        </Form.Label>
                                    </Col>
                                    <Col xs={12}>
                                        <div className="mb-3">
                                            <Form.Label htmlFor="productname">Blog Title*</Form.Label>
                                            <Form.Control
                                                required
                                                id="tourname"
                                                type="text"
                                                onChange={(e) => setTitleUs(e.target.value)} value={titleUs}
                                                className="form-control"
                                                placeholder="Product Title"
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} lg={12}>
                                        <div className="mb-3">
                                            <Form.Label htmlFor="productdesc">
                                                Information*
                                            </Form.Label>
                                            <ReactQuill theme="snow" modules={modules} formats={formats} value={descriptionUs} onChange={setDescriptionUs} />
                                        </div>
                                    </Col>
                                </Row>
                                <div className="d-flex flex-wrap gap-2">
                                {urls.lenght === 0 || idSlug === "" ? <Button type="button" disabled variant="secondary" className="btn" >
                                    Cập Nhập Blog
                                </Button> : <Button type="button" onClick={handleForm} variant="primary" className="btn" >
                                    Cập Nhập Blog
                                </Button>}
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    </div>      
    )
}

export default UpdateBlog