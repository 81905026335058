import React from "react";
import { Image } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import { ref, remove } from "firebase/database";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { db } from "../../Firebase/Firebase.config";

const SingleProductAdmin = ({ data }) => {
    const { image, title, categoryTitle, id, producer, origin } = data;

        const handleRemove = () => {
            remove(ref(db, `product/vi/` + data.id)).then(() => {
                remove(ref(db, `product/us/` + data.id)).then(() => {
                    toast('Xóa Sản Phẩm Thành Công', { hideProgressBar: true, autoClose: 2000, type: 'success' })
                    // window.location.reload(true);
                })
                .catch((error) => {
                    console.log(error)
                })
            })
            .catch((error) => {
                console.log(error)
            })
        }   
    return (
        <div className="h-100">
            <div
                className="popular-tours__singles"
            >
                <div className="popular-tours__img">
                    <Image
                        src={image}
                        alt=""
                    />
                <div className="popular-tours__icon">
                    <Link to={`/product/${id}`}>
                    <i className='bx bx-right-arrow-alt'></i>
                    </Link>
                </div>
                </div>
                <div className="popular-tours__contents">
                    <h3 className="popular-tours__title">
                        <Link to={`/dashboard/update-product/${id}`}>{title}</Link>
                    </h3>
                    <p className="">
                        {categoryTitle}
                    </p>
                    <p className="">
                        {origin} - {producer}
                    </p>
                </div>
                <div className="popular-tours__buttons">
                    <Link to={`/dashboard/update-product/${id}`}>
                        <Button className="mr-3" variant="warning">Update Sản Phẩm</Button>
                    </Link>
                    <Button variant="danger" onClick={handleRemove}>Xóa Sản Phẩm</Button>
                </div>
            </div>
        </div>
    );
};

export default SingleProductAdmin;
