import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import CommonPageHeader from "../../components/CommonPageHeader/CommonPageHeader";
import HomeHeader from "../Home/Home/HomeHeader/HomeHeader";
import BlogDetailsArea from "./BlogDetailsArea/BlogDetailsArea";
import { Helmet } from "react-helmet-async";
import ScrollTotop from "../../components/ScrollToTop";
import { getDatabase, ref, child, get } from "firebase/database";
import HomeFooter from "../Home/Home/HomeFooter/HomeFooter";

const BlogDetails = () => {
  const {id} = useParams();
  const { t, i18n } = useTranslation();

  const [data, setData] = useState([])
  const [dataList, setDataList] = useState([])
  
  useEffect(() => {
    const dbRef = ref(getDatabase());
    get(child(dbRef, `blogs/${i18n.language}/${id}`)).then((snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        setData(data)
      } else {
        console.log("No data available");
      }
    }).catch((error) => {
      console.error(error);
    })
    const dbRefs = ref(getDatabase());
    get(child(dbRefs, `blogs/${i18n.language}`)).then((snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        setDataList(Object.values(data))
      } else {
        console.log("No data available");
      }
    }).catch((error) => {
      console.error(error);
    })
  },[i18n.language])

  return (
    data && (
      <>
        <Helmet>
          <title>
            {`${data.title} - ${t("homepage.company")}`}
          </title>
          <link rel="canonical" href={`/blog-${data.id}`} />
          <meta
            property="og:title"
            content={`${data.title} - ${t("homepage.company")}`}
          />
          <meta
            name="keywords"
            content={`${data.title}, ${t("homepage.company")}, phu nguyen"`}
          />
        </Helmet>
        <ScrollTotop />
        <HomeHeader />
        <CommonPageHeader
          title={t("blog.blogStandard")}
          subtitle={t("blog.blog")}
        />
        <BlogDetailsArea blog={data} blogData={dataList} />
        <HomeFooter />
      </>
    )
  );
};

export default BlogDetails;
