import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import Slider from "react-slick"
import HomeHeroSingleSlide from "../../../../components/HomeHeroSingleSlide/HomeHeroSingleSlide"
import VideoPopup from "../../../../components/VideoPopup/VideoPopup"
import useGlobalContext from "../../../../hooks/useGlobalContext"

const HomeHeroSection = () => {
  const { SlickArrowLeft, SlickArrowRight } = useGlobalContext()

  const settings = {
    autoplay: false,
    autoplaySpeed: 10000,
    dots: false,
    fade: true,
    arrows: true,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false
        }
      }
    ]
  }
  const settingLogo = {
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false
        }
      }
    ]
  }
  return (
    <>
      <VideoPopup videoId="oU_GUAWz52w" />
      <Container>
        <div className="text-center pt-3 pb-3">
          <h5>Tận tâm phục vụ - Đồng hành cùng khách hàng</h5>
        </div>
        <Row className="mt-3 mb-3">
          <Slider className="slider-active" {...settingLogo}>
            <Col
              xs={6}
              md={3}
              className="pr-3 pl-3 d-flex justify-content-center"
            >
              <img
                style={{ height: "50px" }}
                src="https://i.imgur.com/i4fZiZF.jpg"
                alt="logo"
              />
            </Col>
            <Col
              xs={6}
              md={3}
              className="pr-3 pl-3 d-flex justify-content-center"
            >
              <img
                style={{ height: "50px" }}
                src="https://www.ganshorn-medical.com/wp-content/uploads/2022/03/GANSHORN_SCHILLER.svg"
                alt="logo"
              />
            </Col>
            {/* <Col
              xs={6}
              md={3}
              className="pr-3 pl-3 d-flex justify-content-center"
            >
              <img
                style={{ height: "50px" }}
                src="https://i.imgur.com/yWI4lBZ.jpg"
                alt="logo"
              />
            </Col> */}
            <Col
              xs={6}
              md={3}
              className="pr-3 pl-3 d-flex justify-content-center"
            >
              <img
                style={{ height: "50px" }}
                src="https://i.imgur.com/AuJW2Fo.png"
                alt="logo"
              />
            </Col>
            <Col
              xs={6}
              md={3}
              className="pr-3 pl-3 d-flex justify-content-center"
            >
              <img
                style={{ height: "50px" }}
                src="https://i.imgur.com/7BHcebL.png"
                alt="logo"
              />
            </Col>
            <Col
              xs={6}
              md={3}
              className="pr-3 pl-3 d-flex justify-content-center"
            >
              <img
                style={{ height: "50px" }}
                src="https://i.imgur.com/ALhfL9H.png"
                alt="logo"
              />
            </Col>
            <Col
              xs={6}
              md={3}
              className="pr-3 pl-3 d-flex justify-content-center"
            >
              <img
                style={{ height: "50px" }}
                src="https://i.imgur.com/hXlj9jC.png"
                alt="logo"
              />
            </Col>
            <Col
              xs={6}
              md={3}
              className="pr-3 pl-3 d-flex justify-content-center"
            >
              <img
                style={{ height: "60px" }}
                src=" https://i.imgur.com/U3tWA5V.jpg"
                alt="logo"
              />
            </Col>
            <Col
              xs={6}
              md={3}
              className="pr-3 pl-3 d-flex justify-content-center"
            >
              <img
                style={{ height: "50px" }}
                src="https://i.imgur.com/gAiG7fO.jpg"
                alt="logo"
              />
            </Col>
            <Col
              xs={6}
              md={3}
              className="pr-3 pl-3 d-flex justify-content-center"
            >
              <img
                style={{ height: "50px" }}
                src="https://i.imgur.com/2tvxUhz.jpg"
                alt="logo"
              />
            </Col>
            <Col
              xs={6}
              md={3}
              className="pr-3 pl-3 d-flex justify-content-center"
            >
              <img
                style={{ height: "50px" }}
                src="https://i.imgur.com/nsoDjy4.jpg"
                alt="logo"
              />
            </Col>
            <Col
              xs={6}
              md={3}
              className="pr-3 pl-3 d-flex justify-content-center"
            >
              <img
                style={{ height: "50px" }}
                src="https://i.imgur.com/uEcBykp.jpg"
                alt="logo"
              />
            </Col>
            <Col
              xs={6}
              md={3}
              className="pr-3 pl-3 d-flex justify-content-center"
            >
              <img
                style={{ height: "50px" }}
                src="https://i.imgur.com/QwyDeCn.png"
                alt="logo"
              />
            </Col>
            <Col
              xs={6}
              md={3}
              className="pr-3 pl-3 d-flex justify-content-center"
            >
              <img
                style={{ height: "50px" }}
                src="https://i.imgur.com/bTwGrfz.jpg"
                alt="logo"
              />
            </Col>
          </Slider>
        </Row>
      </Container>

      <section className="hero-area">
        <div className="hero-slider">
          <Slider className="slider-active" {...settings}>
            <HomeHeroSingleSlide bg_className="1" />
          </Slider>
        </div>
      </section>
    </>
  )
}

export default HomeHeroSection
