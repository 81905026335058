import React, { useState } from "react";
import {
  Button,
  Dropdown
} from "react-bootstrap";

// users
import user1 from "../../assets/images/loader.png";
import { Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import logOut from "../../Firebase/auth/logout";

const ProfileMenu = () => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const [error, setError] = React.useState(null)
  const navigate = useNavigate()


    const handleLogOut = async (event) => {
      event.preventDefault()

      const { result, error } = await logOut();

      if (error) {
          return setError("Incorrect account or password")
      }

      // else successful
      return navigate("/")
  }

  return (
    <>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <Dropdown.Toggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <Image
            className="rounded-circle header-profile-user"
            src={user1}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-2 me-1">Admin</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu-end">
          <Button type="button" onClick={handleLogOut} className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>Log Out</span>
          </Button>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default ProfileMenu
