import React from "react";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import { Link, useNavigate } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";

const AboutAppoinment = () => {
  const { t } = useTranslation();

  const settingLogo = {
    slidesToShow: 4,
    slidesToScroll: 1,
    // autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };
  const navigation = useNavigate()

  const handleViewBook = product => {
    navigation({
        pathname: '/shop',
        search: `?product=${product}&producer=all`,
        params: {
           product
        },
    })
  }
  return (
    <>
      <section
        className="appoinment-section pt-120 pb-120"
        data-background="img/bg/appointment.jpg"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="appoinment-box white">
                <div className="appoinment-content">
                  <span className="small-text">
                    {t("aboutus.approch.approch")}
                  </span>
                  <h1>{t("aboutus.approch.approchContent")}</h1>
                  <p>{t("aboutus.approch.approchDescription")}</p>
                      <Row className="mt-3 mb-3">
                      <Slider className="slider-active" {...settingLogo}>
                        <Col xs={6} md={3} className="pr-3 pl-3 d-flex justify-content-center">
                        <img style={{height: "70px"}} src="https://i.imgur.com/i4fZiZF.png" alt="logo" />
                        </Col>
                        <Col xs={6} md={3} className="pr-3 pl-3 d-flex justify-content-center">
                          <img style={{height: "50px"}} src="https://i.imgur.com/yWI4lBZ.jpg" alt="logo" />
                        </Col>
                        <Col xs={6} md={3} className="pr-3 pl-3 d-flex justify-content-center">
                          <img style={{height: "50px"}} src="https://i.imgur.com/AuJW2Fo.png" alt="logo" />
                        </Col>
                        <Col xs={6} md={3} className="pr-3 pl-3 d-flex justify-content-center">
                          <img style={{height: "50px"}} src="https://i.imgur.com/hXlj9jC.png" alt="logo" />
                        </Col>
                        <Col xs={6} md={3} className="pr-3 pl-3 d-flex justify-content-center">
                          <img style={{height: "50px"}} src="https://i.imgur.com/7BHcebL.png" alt="logo" />
                        </Col>
                        <Col xs={6} md={3} className="pr-3 pl-3 d-flex justify-content-center">
                          <img style={{height: "50px"}} src="https://i.imgur.com/ALhfL9H.png" alt="logo" />
                        </Col>
                        <Col xs={6} md={3} className="pr-3 pl-3 d-flex justify-content-center">
                          <img style={{height: "50px"}} src="https://i.imgur.com/gAiG7fO.jpg" alt="logo" />
                        </Col>
                        <Col xs={6} md={3} className="pr-3 pl-3 d-flex justify-content-center">
                          <img style={{height: "50px"}} src=" https://i.imgur.com/U3tWA5V.jpg" alt="logo" />
                        </Col>
                        <Col xs={6} md={3} className="pr-3 pl-3 d-flex justify-content-center">
                          <img style={{height: "50px"}} src="https://i.imgur.com/2tvxUhz.jpg" alt="logo" />
                        </Col>
                        <Col xs={6} md={3} className="pr-3 pl-3 d-flex justify-content-center">
                          <img style={{height: "50px"}} src="https://i.imgur.com/nsoDjy4.jpg" alt="logo" />
                        </Col>
                        <Col xs={6} md={3} className="pr-3 pl-3 d-flex justify-content-center">
                          <img style={{height: "50px"}} src="https://i.imgur.com/uEcBykp.jpg" alt="logo" />
                        </Col>
                        <Col xs={6} md={3} className="pr-3 pl-3 d-flex justify-content-center">
                          <img style={{height: "50px"}} src="https://i.imgur.com/QwyDeCn.png" alt="logo" />
                        </Col>
                        <Col xs={6} md={3} className="pr-3 pl-3 d-flex justify-content-center">
                          <img style={{height: "50px"}} src="https://i.imgur.com/bTwGrfz.jpg" alt="logo" />
                        </Col>
                        </Slider>
                    </Row>
                </div>
                <a onClick={() => handleViewBook("0")} className="primary_btn mt-40 cursor-pointer">
                  {t("header.buynow")}
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutAppoinment;
