import { BrowserRouter, Route, Routes } from "react-router-dom";
import ScrollTop from "./components/ScrollTop";
import AllContext from "./context/AllContext";
import AboutUs from "./pages/AboutUs/AboutUs";
import BlogDetails from "./pages/BlogDetails/BlogDetails";
import Blogs from "./pages/Blogs/Blogs";
import Contact from "./pages/Contact/Contact";
import Home from "./pages/Home/Home/Home";
import ShopDetails from "./pages/ShopDetails/ShopDetails/ShopDetails";
import ShopPage from "./pages/ShopPage/ShopPage/ShopPage";
import "./App.css";
import NotFound from "./pages/NotFound/NotFound";
import Dashboard from "./pages/Dashboard/Dashboard";
import SignIn from "./pages/SignIn/signin";
import AddProduct from "./pages/Dashboard/AddProduct/AddProduct";
import ListProduct from "./pages/Dashboard/ListProduct/ListProduct";
import ListBlog from "./pages/Dashboard/ListBlog/ListBlog";
import AddBlog from "./pages/Dashboard/AddBlog/AddBlog";
import ContactList from "./pages/Dashboard/ContactList/ContactList";
import { ToastContainer } from "react-toastify";
import UpdateProductPage from "./pages/Dashboard/UpdateProduct";
import UpdateBlogPage from "./pages/Dashboard/UpdateBlog";

function App() {

  return (
    <>
      <ToastContainer />
      <AllContext>
        <BrowserRouter>
          <ScrollTop />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/shop" element={<ShopPage />} />
            <Route path="/product/:id" element={<ShopDetails />} />
            <Route path="/blogs" element={<Blogs />} />
            <Route path="/blog/:id" element={<BlogDetails />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/signin" element={<SignIn />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/notMatch" element={<NotFound />} />
            <Route path="*" element={<NotFound />} />
            <Route path='/dashboard' element={<Dashboard />}>
              <Route path='product-list' element={<ListProduct />} />
              <Route path='update-product/:id' element={<UpdateProductPage />} />
              <Route path='update-blog/:id' element={<UpdateBlogPage />} />
              <Route path='blog-list' element={<ListBlog />} />
              <Route path='add-product' element={<AddProduct />} />
              <Route path='add-blog' element={<AddBlog />} />
              <Route path='contact-list' element={<ContactList />} />
              {/* <Route path='order-list' element={<OrderList />} /> */}
            </Route>
            
          </Routes>
        </BrowserRouter>
      </AllContext>
    </>
  );
}

export default App;
