// Import the functions you need from the SDKs you need
import { initializeApp, getApps } from "firebase/app";
import { getStorage } from "firebase/storage"
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfigMain = {
  apiKey: "AIzaSyAIkdNtUYdJldVClqV_sVxX5ycn5h1oKm8",
  authDomain: "phunguyen-med.firebaseapp.com",
  databaseURL: "https://phunguyen-med-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "phunguyen-med",
  storageBucket: "phunguyen-med.appspot.com",
  messagingSenderId: "1041733688995",
  appId: "1:1041733688995:web:a49f273fa4fc97a6326916",
  measurementId: "G-PNZW8X6J2N"
};

// Initialize Firebase
export const firebaseConfig = firebaseConfigMain
const app = initializeApp(firebaseConfig);

export const db = getDatabase(app);

let firebase_app = getApps().length === 0 ? initializeApp(firebaseConfigMain) : getApps()[0];

export default firebase_app;

export const auth = getAuth(app);



export const storage = getStorage()