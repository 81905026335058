import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import SingleSecondShop from "../../../../components/SingleSecondShop/SingleSecondShop";
import SingleShop from "../../../../components/SingleShop/SingleShop";
import {FaThLarge, FaList} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Pagination from "../../../../components/Pagination";


const ShopBanner = ({productId, producerId, data}) => {
  const { t, i18n } = useTranslation();
  // const Data = i18n.language === "us" ? dataUS : dataVI;
  const navigation = useNavigate ()
  const [productList, setProductList] = useState(productId);
  const [currentPage, setCurrentPage] = useState(1)
  const [producer, setProducer] = useState(producerId === "all" ? null : producerId)
  
 
  let product;
  let productCategory;
  let cachedObject = {};

  if(parseInt(productId) === 0){
    product = data
  } else {
    product = data.filter(
      (item) => item.category === productId
      )
  }

  if(producerId !== "all"){
    productCategory = product.filter(
      (item) => item.producer === producer
      )
  }
  console.log(product)

  let options = product.length > 0 && product.map(function (category) {
          return { value: category.producer, label: category.producer }
      })

  options.map((item) => (cachedObject[item.value] = item));
  options = Object.values(cachedObject);

  const handleSetProduct = (e) => {
    setProductList(e)
    setProducer(null)
  }


  useEffect(() => {
    setCurrentPage(1)
    navigation({
      pathname: '/shop',
      search: `?product=${productList}&producer=${producer === null ? "all" : producer}`,
      params: {
        producer
      },
    })
  },[productList,producer])

  let PageSize = 12

  let dataProduct= productCategory ? productCategory : product

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize
    const lastPageIndex = firstPageIndex + PageSize
    return dataProduct.slice(firstPageIndex, lastPageIndex)
  }, [currentPage, dataProduct])

  const onChangeClickPage = (page) => {
    setCurrentPage(page)
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  console.log(options)


  return (
    <>
      <section className="shop-banner-area">
        <div className="container">
          <div className="row mt-20">
            <div className="col-xl-4 col-lg-5 col-md-6">
              <div className="h-100 d-flex align-items-center">
                <h3>{t("shoppage.ProductPortfolio")}</h3>
              </div>
            </div>
            <div className="col-xl-8 col-lg-7 col-md-6">
              <div className="shop-tab f-right mb-3 mb-lg-0 d-none d-lg-block">
                <ul className="nav text-center" id="myTab" role="tablist">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="home-tab"
                      data-bs-toggle="tab"
                      href="#home"
                      role="tab"
                      aria-controls="home"
                      aria-selected="true"
                    >
                      <FaThLarge />
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="profile-tab"
                      data-bs-toggle="tab"
                      href="#profile"
                      role="tab"
                      aria-controls="profile"
                      aria-selected="false"
                    >
                    <FaList />
                    </a>
                  </li>
                </ul>
              </div>
              <div className="pro-filter mb-3 mt-3 mt-lg-0 mb-lg-0 f-right">
                <select
                  name="pro-filter"
                  id="pro-filter"
                  value={productId}
                  onChange={(e) => handleSetProduct(e.target.value)}
                  className="mr-1"
                >
                  <option defaultValue="0" value="0">
                    {t("shoppage.AllProduct")}
                  </option>
                  <option value="1">{t("shoppage.Medicalandforensicpathologyequipment")}</option>
                  <option value="2">{t("shoppage.Laboratoryequipment")}</option>
                  <option value="3">
                    {t("shoppage.FunctionalProbesandSportsMedicine")}
                  </option>
                  <option value="4">{t("shoppage.Diagnosticimagingequipment")}</option>
                  <option value="5">{t("shoppage.Medicalandindustriallaboratoryequipment")}</option>
                  <option value="6">{t("shoppage.Consumablesupplies")}</option>
                  </select>
                {productCategory ? <select
                  name="pro-filter"
                  id="pro-filter"
                  value={producerId}
                  onChange={(e) => setProducer(e.target.value)}
                >
                  {options.map((item, i) => {
                    return <option value={item.value}>{item.label}</option>
                  })}
                  </select> : <select
                  name="pro-filter"
                  id="pro-filter"
                  value={producerId}
                  onChange={(e) => setProducer(e.target.value)}
                >
                  <option defaultValue="all" value="all">
                    {t("shoppage.allproducer")}
                  </option>
                  {options.map((item, i) => {
                    return <option value={item.value}>{item.label}</option>
                  })}
                  </select>}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <h5 className="mb-30">
                {t("shoppage.selectCategory")}
                {productList === "0" ? (
                  <b style={{ color: "#8fb569" }}>
                    {" "}
                    {t("shoppage.AllProduct")}
                  </b>
                ) : (
                  <b style={{ color: "#8fb569" }}> {product.length > 0 ? `${product[0].categoryTitle} - ${producerId === "all" ? t("shoppage.allproducer") : producerId}` :  `${producerId === "all" ? t("shoppage.allproducer") : producerId}`}</b>
                )}
              </h5>
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="home"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  <div className="row">
                    {currentTableData.map((item, i) => {
                      return (
                        <SingleShop
                          image={item.image}
                          title={item.title}
                          data={item}
                          styleCol="col-lg-4 col-md-6 mb-3"
                        />
                      );
                    })}
                    <Pagination
                      className='pagination-bar'
                      currentPage={currentPage}
                      totalCount={dataProduct.length}
                      pageSize={PageSize}
                      onPageChange={(page) => onChangeClickPage(page)}
                    />
                  </div> 
                </div>
                <div
                  className="tab-pane fade"
                  id="profile"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                <div>
                    {currentTableData.map((item, i) => {
                      return (
                        <div className="row">
                        <SingleSecondShop
                          image={item.image}
                          data={item}
                        />
                      </div>
                      );
                    })}
                    <Pagination
                      className='pagination-bar'
                      currentPage={currentPage}
                      totalCount={dataProduct.length}
                      pageSize={PageSize}
                      onPageChange={(page) => onChangeClickPage(page)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ShopBanner;
