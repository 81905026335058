import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CommonPageHeader from "../../components/CommonPageHeader/CommonPageHeader";
import HomeHeader from "../Home/Home/HomeHeader/HomeHeader";
import BlogsArea from "./BlogsArea/BlogsArea";
import { Helmet } from "react-helmet-async";
import HomeFooter from "../Home/Home/HomeFooter/HomeFooter";
import ScrollTotop from "../../components/ScrollToTop";
import { getDatabase, ref, child, get } from "firebase/database";

const Blogs = () => {
  const { t, i18n } = useTranslation();

  const [data, setData] = useState([])
  
  useEffect(() => {
    const dbRef = ref(getDatabase());
    get(child(dbRef, `blogs/${i18n.language}`)).then((snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        setData(Object.values(data))
      } else {
        console.log("No data available");
      }
    }).catch((error) => {
      console.error(error);
    })
  },[i18n.language])
  return (
    <>
      <Helmet>
        <title>{t("blog.title")}</title>
        <link rel="canonical" href="https://www.tacobell.com/" />
        <meta property="og:title" content={t("blog.title")} />
      </Helmet>
      <ScrollTotop />
      <HomeHeader />
      <CommonPageHeader
        title={t("blog.blogStandard")}
        subtitle={t("blog.blog")}
      />
      {data && <BlogsArea blog={data} blogData={data} />}
      <HomeFooter />
    </>
  );
};

export default Blogs;
