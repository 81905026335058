import React from "react";
import { Link } from "react-router-dom";

const HomeSingleFooterBlog = ({ image, title, time, id }) => {
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  // const formattedDate = new Intl.DateTimeFormat('en-US', options).format(time);
  return (
    <div className="col-12 mb-20">
      <div className="signle-blog-feeds ">
        <div className="blog-feeds-thumb">
          <Link to={`/blog/${id}`}>
            <img
              src={image}
              alt=""
              style={{ width: "90px", height: "90px" }}
            />
          </Link>
        </div>
        <div className="blog-feeds-text">
          <h5>
            <Link to={`/blog/${id}`}>{title}</Link>
          </h5>
          {/* <span className="feeds-date">{formattedDate}</span> */}
        </div>
      </div>
    </div>
  );
};

export default HomeSingleFooterBlog;
