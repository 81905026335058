import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import SingleShop from "../../../../components/SingleShop/SingleShop";

import { Link, useNavigate } from "react-router-dom";
import useGlobalContext from "../../../../hooks/useGlobalContext";
import Slider from "react-slick";

function ShopCategory({ category, data }) {
  const { t } = useTranslation();
  let producer = data.filter((item) => item.category === category);
  const { SlickArrowLeft, SlickArrowRight } = useGlobalContext();

  const settings = {
    autoplay: true,
    autoplaySpeed: 10000,
    dots: false,
    arrows: true,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 564,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  return (
    <>
      {producer.length > 2 && <section className="shop-banner-area">
        <div className="container">
          <div className="row mt-20 mb-30">
            <div className="col-10">
              <div className="h-100 d-flex align-items-center">
                <h3>{producer[0].categoryTitle}</h3>
              </div>
            </div>
            <div className="col-2">
              <a href={`/shop?product=${category}&producer=all`}>
                <h6 className="d-flex align-items-center justify-content-end mb-0 h-100 cursor-pointer">
                  {t("header.seemore")}
                </h6>
              </a>
            </div>
          </div>
          <div className="row">
            {producer.length > 2 ? <div className="col">
              <Slider className="slider-items" {...settings}>
                {producer.map((item, i) => {
                  return (
                    <div key={i} className="h-100">
                      <SingleShop
                        image={item.image}
                        title={item.title}
                        data={item}
                        styleCol="mb-3 m-2 h-100 "
                        styleTiltle="text-one-row"
                      />
                    </div>
                  );
                })}
              </Slider>
            </div>
            : <></>}
          </div>
        </div>
      </section>}
    </>
  );
}

export default ShopCategory;
