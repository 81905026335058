import React, { useState, useEffect } from "react";
import { useWindowScroll } from "react-use";
import { AiOutlineUp } from "react-icons/ai";

function ScrollTotop() {
  const { y: pageYOffset } = useWindowScroll();
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    if (pageYOffset > 400) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  }, [pageYOffset]);

  const scrollToTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  if (!visible) {
    return false;
  }

  return (
    <div className='scroll-to-top cursor-pointer text-center' onClick={scrollToTop}>
      <AiOutlineUp size="24px" className='h-100' />
    </div>
  );
}

export default ScrollTotop;