import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { FaHeart, FaExpand} from "react-icons/fa";

const SingleSecondShop = ({ image, data }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="col-lg-4 col-md-6 mb-3">
        <div className="product mb-30">
          <div className="product__img">
            <Link to={`/product/${data.id}`}>
              <img src={data.image} alt="" className="product__img__one" />
            </Link>
          </div>
        </div>
      </div>
      <div className="col-lg-8">
        <div className="product-list-content pt-10 mb-30">
          <div className="product__content mb-20">
            <h4 className="pro-title">
              <Link to={`/product/${data.id}`}>{data.title}</Link>
            </h4>
            <div className="price">
              <span className="text-danger">
                {data.price ? data.price : t("header.contact")}
              </span>
            </div>
          </div>
          <p>
            {t("product.producer")}: <b>{data.producer}</b>
          </p>
          <p>
            {t("product.origin")}: <b>{data.origin}</b>
          </p>
          <div className="product-action-list">
            <Link className="primary_btn btn-theme" to="/contact">
              {data.price ? "add to cart" : t("header.contact")}
            </Link>
            <a className="action-btn" href={`/product/${data.id}`}>
              <FaHeart />
            </a>
            {/* <Link className="action-btn" to={`/product/${data.id}`}>
              <FaExpand />
            </Link> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleSecondShop;
