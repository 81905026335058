import { ref, set } from "firebase/database";
import { db } from '../Firebase.config'

export default async function addDataUs(data){
    set(ref(db, `product/us/` + data.id), {
        id: data.id,
        image: data.image,
        title: data.title,
        price: data.price,
        imageslide: data.imageslide,
        description: data.description,
        star: data.star,
        producer: data.producer,
        origin: data.origin,
        categoryTitle: data.categoryTitle,
        category: data.category,
    });
}