import React, { useState } from "react";
import { Link, useNavigate  } from "react-router-dom";
import Sidebar from "../../../../components/Shared/Sidebar/Sidebar";
import useGlobalContext from "../../../../hooks/useGlobalContext";
import { useTranslation } from "react-i18next";
import { AiOutlinePhone, AiOutlineMail } from "react-icons/ai";
import { FaFacebookF, FaBars} from "react-icons/fa";
import { Col, Row } from "react-bootstrap";


const HomeHeader = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { stickyMenu } = useGlobalContext();
  const { t, i18n } = useTranslation();
  const navigation = useNavigate ()

  const handleViewBook = product => {
    navigation({
        pathname: '/shop',
        search: `?product=${product}&producer=all`,
        params: {
           product
        },
    })
  }
  return (
    <>
      <header>
        <div className="top-bar d-none d-md-block">
          <div className="container">
            <Row className="row d-flex align-items-center">
              <Col xs={6}>
                <div className="header-info">
                  <span>
                    <AiOutlinePhone size={18} className="mtup-5px"/> <a href="tel:02466539696">0246.653.9696</a>
                  </span>
                  <span>
                  <a href="mailto: qd.chu@phunguyen.vn"><AiOutlineMail size={18} className="mtup-5px"/> {t("contact.email")}</a>
                  </span>
                </div>
              </Col>
              <Col xs={6}>
                <div className="header-top-right-btn f-right">
                  <Link to="/contact" className="primary_btn">
                    {t("header.contact")}
                  </Link>
                </div>
              </Col>
            </Row>
          </div>
        </div>

        <div
          className={
            stickyMenu ? "header-menu-area sticky_menu" : "header-menu-area"
          }
        >
          <div className="container menu_wrapper">
            <div className="row align-items-center">
              <div className="col-xl-3 col-lg-3 col-md-6 col-6 d-flex align-items-center">
                <div className="logo pos-rel">
                  <Link to="/">
                    <img src="https://i.imgur.com/V8v96B8.png" alt="" />
                  </Link>
                </div>
              </div>
              <div className="col-xl-9 col-lg-9 col-md-6 col-6">
                <div className="header-right f-right">
                  <div className="header-lang f-right pos-rel d-none d-lg-block">
                    <div className="lang-icon">
                      <img src={`https://i.imgur.com/${i18n.language === "vi" ? "IvRv0bg" : "dV4EE7F"}.png`} alt="" />
                      </div>
                    <ul className="header-lang-list">
                      <li>
                        <a href="#" onClick={() => i18n.changeLanguage("us")}>
                          US
                        </a>
                      </li>
                      <li>
                        <a href="#" onClick={() => i18n.changeLanguage("vi")}>
                          VI
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="header-social-icons f-right d-none d-xl-block">
                    <ul>
                      <li>
                        <a href="https://www.facebook.com/profile.php?id=100089830737464&mibextid=LQQJ4d" target="_blank">
                          <FaFacebookF size={16} className="mtup-5px"/>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="header__menu f-right">
                  <nav id="mobile-menu">
                    <ul>
                      <li>
                        <Link to="/">{t("header.home")}</Link>
                        {/* <ul className="submenu">
                                       <li><Link to="/">Home style 1</Link></li>
                                       <li><Link to="/homeTwo">Home style 2</Link></li>
                                       <li><Link to="/homeThree">Home style 3</Link></li>
                                       <li><Link to="/homeFour">Home style 4</Link></li>
                                       <li><Link to="/homeFive">Home style 5</Link></li>
                                    </ul> */}
                      </li>
                      {/* <li>
                        <Link to="/services">Department +</Link>
                        <ul className="submenu">
                          <li>
                            <Link to="/services">Services 01</Link>
                          </li>
                          <li>
                            <Link to="/servicesTwo">Services 02</Link>
                          </li>
                          <li>
                            <Link to="/servicesDetails">Services Details</Link>
                          </li>
                        </ul>
                      </li> */}
                      {/* <li>
                        <Link to="/doctors">Doctors +</Link>
                        <ul className="submenu">
                          <li>
                            <Link to="/doctors">Doctors 01</Link>
                          </li>
                          <li>
                            <Link to="/doctorsTwo">Doctors 02</Link>
                          </li>
                          <li>
                            <Link to="/doctorDetails">Doctors Details</Link>
                          </li>
                        </ul>
                      </li> */}
                      <li>
                        <a className="cursor-pointer" onClick={() => handleViewBook("0")}>{t("header.product")}</a>
                        {/* <ul className="submenu">
                          <li>
                            <Link to="/shop">Shop Page</Link>
                          </li>
                          <li>
                            <Link to="/shopDetails">Shop Details</Link>
                          </li>
                          <li>
                            <Link to="/shoppingCart">Shopping Cart</Link>
                          </li>
                          <li>
                            <Link to="/checkout">Checkout</Link>
                          </li>
                          <li>
                            <Link to="/wishList">Wishlist</Link>
                          </li>
                          <li>
                            <Link to="/login">Login</Link>
                          </li>
                          <li>
                            <Link to="/register">Register</Link>
                          </li>
                        </ul> */}
                      </li>
                      <li>
                        <Link to="/about">{t("header.about")}</Link>
                      </li>
                      <li>
                        <Link to="/contact">{t("header.contact")}</Link>
                      </li>
                      <li>
                        <Link to="/blogs">{t("header.news")}</Link>
                        {/* <ul className="submenu">
                          <li>
                            <Link to="/blogs">Blog Right Sidebar</Link>
                          </li>
                          <li>
                            <Link to="/blogLeftSideBar">Blog Left Sidebar</Link>
                          </li>
                          <li>
                            <Link to="/blogNoSideBar">Blog No Sidebar</Link>
                          </li>
                          <li>
                            <Link to="/blogTwoColumn">Blog 2 Column</Link>
                          </li>
                          <li>
                            <Link to="/blogTwoMasonry">Blog 2 Col Masonry</Link>
                          </li>
                          <li>
                            <Link to="/blogThreeColumn">Blog 3 Column</Link>
                          </li>
                          <li>
                            <Link to="/blogThreeColMasonry">
                              Blog 3 Col Masonry
                            </Link>
                          </li>
                          <li>
                            <Link to="/blogDetails">Blog Details</Link>
                          </li>
                          <li>
                            <Link to="/blogDetailsLeftSidebar">
                              Details Left Sidebar
                            </Link>
                          </li>
                          <li>
                            <Link to="/blogDetailsAudio">Details Audio</Link>
                          </li>
                          <li>
                            <Link to="/blogDetailsVideo">Details Video</Link>
                          </li>
                          <li>
                            <Link to="/blogDetailsGallery">
                              Details Gallery
                            </Link>
                          </li>
                        </ul> */}
                      </li>
                      {/* <li>
                        <a href="#">Pages +</a>
                        <ul className="submenu">
                          <li>
                            <Link to="/about">About</Link>
                          </li>
                          <li>
                            <Link to="/appoinment">Appoinment</Link>
                          </li>
                          <li>
                            <Link to="/portfolioTwoColumn">
                              Portfolio 2 column
                            </Link>
                          </li>
                          <li>
                            <Link to="/portfolioThreeColumn">
                              Portfolio 3 column
                            </Link>
                          </li>
                          <li>
                            <Link to="/portfolioSlider">Portfolio Slider</Link>
                          </li>
                          <li>
                            <Link to="/contact">Contact</Link>
                          </li>
                          <li>
                            <Link to="/notMatch">404 page</Link>
                          </li>
                        </ul>
                      </li> */}
                    </ul>
                  </nav>
                </div>

                <div className="side-menu-icon d-lg-none text-end">
                  <button
                    onClick={handleShow}
                    className="side-toggle border-0 bg-transparent"
                  >
                    <FaBars size={30} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <Sidebar show={show} handleClose={handleClose} />
    </>
  );
};

export default HomeHeader;
