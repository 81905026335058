import React, { useEffect, useState } from 'react'
import { getDatabase, ref, child, get } from "firebase/database";
import { useParams } from 'react-router-dom';
import UpdateBlog from './UpdateBlog';
function UpdateBlogPage() {
        let {id} = useParams()
        const [dataVi, setDataVi] = useState(null)
        const [dataUs, setDataUs] = useState(null)
    
        useEffect(() => {
            const dbRef = ref(getDatabase());
            get(child(dbRef, `blogs/vi/${id}`)).then((snapshot) => {
            if (snapshot.exists()) {
                const data = snapshot.val();
                setDataVi(data)
            } else {
                console.log("No data available");
            }
            }).catch((error) => {
            console.error(error);
            })
            const dbRefs = ref(getDatabase());
            get(child(dbRefs, `blogs/us/${id}`)).then((snapshot) => {
            if (snapshot.exists()) {
                const data = snapshot.val();
                setDataUs(data)
            } else {
                console.log("No data available");
            }
            }).catch((error) => {
            console.error(error);
            })
        },[])

    return (
        dataVi && dataUs && <UpdateBlog blogVi={dataVi} blogUs={dataUs} />
    )
}

export default UpdateBlogPage