import { ref, set } from "firebase/database";
import { db } from '../Firebase.config'

export default async function addBlogVi(data){
    set(ref(db, 'blogs/vi/' + data.id), {
        id: data.id,
        image: data.image,
        title: data.title,
        description: data.description,
        date: data.date,
        author: data.author
    });
}