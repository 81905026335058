import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import HomeSingleFooterBlog from "../../../../components/HomeSingleFooterBlog/HomeSingleFooterBlog";
import { FaRegEnvelope, FaRegFlag} from "react-icons/fa";
import { getDatabase, ref, child, get } from "firebase/database";

const HomeFooter = () => {
  const { t, i18n } = useTranslation()
  // const data = i18n.language === "us" ? blogEN : blogVI;
  const navigation = useNavigate()
  const [data, setData] = useState([])
    
  useEffect(() => {
  const dbRef = ref(getDatabase());
  get(child(dbRef, `blogs/${i18n.language}`)).then((snapshot) => {
      if (snapshot.exists()) {
      const data = snapshot.val();
      setData(Object.values(data))
      } else {
      console.log("No data available");
      }
  }).catch((error) => {
      console.error(error);
  })
  },[])

  const handleViewBook = product => {
    navigation({
        pathname: '/shop',
        search: `?product=${product}&producer=all`,
        params: {
          product
        },
    })
  }

  return (
    <>
      <footer>
        <div className="footer-top primary-bg footer-map pos-rel pt-120 pb-80">
          <div className="container">
            <div
              className="row"
              style={{ position: "relative", zIndex: "9999" }}
            >
              <div className="footer-logo mb-35">
                <Link to="/">
                  <img src="img/logo/logo.png" alt="" />
                </Link>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6">
                <div className="footer-contact-info footer-contact-info-3 mb-40">
                  <div className="footer-contact-content mb-25">
                    <p>{t("footer.about")}</p>
                  </div>
                  <div className="footer-emailing">
                    <ul>
                      <li>
                        <FaRegEnvelope className="mr-1"/> {" "}
                        <a href="mailto: qd.chu@phunguyen.vn"> {t("contact.email")}</a>
                      </li>
                      <li>
                        <FaRegFlag className="mr-1"/>{" "}
                        {t("footer.live")}
                      </li>
                      <li>
                        <FaRegFlag className="mr-1"/>{" "}
                        {t("footer.live2")}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6">
                <div className="footer-widget mb-40">
                  <div className="footer-title">
                    <h3> {t("footer.quicklink")}</h3>
                  </div>
                  <div className="footer-menu footer-menu-2">
                    <ul>
                      <li>
                        <Link to="/"> {t("header.home")}</Link>
                      </li>
                      <li>
                        <a className="shop-footer" onClick={() => handleViewBook("0")}>{t("header.product")}</a>
                      </li>
                      <li>
                        <Link to="/about"> {t("header.about")}</Link>
                      </li>
                      <li>
                        <Link to="/contact"> {t("header.contact")}</Link>
                      </li>
                      <li>
                        <Link to="/blogs"> {t("header.news")}</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6">
                <div className="footer-widget mb-40">
                  <div className="footer-title">
                    <h3> {t("footer.newFeed")}</h3>
                  </div>
                  <div className="row">
                    {data.slice(0,3).map((item, i) => {
                      return (
                        <HomeSingleFooterBlog
                          image={item.image}
                          title={item.title}
                          time={item.date}
                          id={item.id}
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom pt-25 pb-20">
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="footer-copyright footer-copyright-3 text-center">
                  <p>Copyright by@ Phu Nguyen Technology Trading Co., Ltd</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default HomeFooter;
