import React from "react";
import { useTranslation } from "react-i18next";

const BlogRightSide = ({ blogData }) => {
  const { t } = useTranslation();
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return (
    <>
      <div className="col-lg-4">
        <div className="widget mb-40">
          <div className="widget-title-box mb-30">
            <span className="animate-border"></span>
            <h3 className="widget-title">{t("blog.popularfeeds")}</h3>
          </div>
          <ul className="recent-posts">
            {blogData.slice(0,5).map((item, i) => {
              return (
                <li key={i}>
                  <div className="widget-posts-image">
                    <a href={`blog-${item.id}`}>
                      <img src={item.image} alt="" />
                    </a>
                  </div>
                  <div className="widget-posts-body">
                    <h6 className="widget-posts-title">
                      <a href={`blog/${item.id}`}>{item.title}</a>
                    </h6>
                    <p className="widget-posts-meta mb-0">{new Intl.DateTimeFormat('en-US', options).format(item.date)}</p>
                  </div>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </>
  );
};

export default BlogRightSide;
