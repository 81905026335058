import React, { useEffect, useState } from "react";
import CommonPageHeader from "../../../components/CommonPageHeader/CommonPageHeader";
import HomeHeader from "../../Home/Home/HomeHeader/HomeHeader";
import ShopDetailsBanner from "./ShopDetailsBanner/ShopDetailsBanner";
import ShopDetailsDesc from "./ShopDetailsDesc/ShopDetailsDesc";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import HomeFooter from "../../Home/Home/HomeFooter/HomeFooter";
import ScrollTotop from "../../../components/ScrollToTop";
import { getDatabase, ref, child, get } from "firebase/database";

const ShopDetails = () => {
  const {id} = useParams();
  const { t, i18n } = useTranslation();
  const [data, setData] = useState(null)
  
  useEffect(() => {
    const dbRef = ref(getDatabase());
    get(child(dbRef, `product/${i18n.language}/${id}`)).then((snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        setData(data)
      } else {
        console.log("No data available");
      }
    }).catch((error) => {
      console.error(error);
    })
  },[i18n.language])


console.log(data)

  return (
    data && (
      <>
        <Helmet>
          <title>
            {`${data.title} - ${t("homepage.company")}`}
          </title>
          <link rel="canonical" href={`/${data.id}`} />
          <meta
            property="og:title"
            content={`${data.title} - ${t("homepage.company")}`}
          />
          <meta
            name="keywords"
            content={`
              ${data.title}, ${t("homepage.company")}, phu nguyen`}
          />
        </Helmet>
        <ScrollTotop />
        <HomeHeader />
        <CommonPageHeader
          title={t("details.product_details")}
          subtitle={t("details.details")}
        />
        <ShopDetailsBanner product={data} />
        <ShopDetailsDesc product={data} />
        <HomeFooter />
      </>
    )
  );
};

export default ShopDetails;
