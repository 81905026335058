import React, { useEffect, useState } from 'react'
import useAuth from '../../hooks/useAuth';
import { Outlet, useNavigate } from 'react-router-dom';
import LayoutDashboard from '../../components/Layout/DashboardLayout';
import Preloader from '../../components/Preloader/Preloader';

function Dashboard() {
    const { user } = useAuth()
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate()

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (user == null) navigate("/signin")
            setLoading(false)
          }, 5000);
          return () => clearTimeout(timeoutId);  
    }, [user])

  return (
    <>
        {user ? <LayoutDashboard>
            <Outlet />  
        </LayoutDashboard> : <Preloader loading={loading} />}
    </>
    
  )
}

export default Dashboard