import React, { useMemo, useState } from "react";
import BlogFirstArticle from "./BlogFirstArticle";
import BlogRightSide from "./BlogRightSide";
import Pagination from "../../../components/Pagination";

const BlogsArea = ({ blog }) => {
  console.log(blog)
  const [currentPage, setCurrentPage] = useState(1)

  let PageSize = 2


  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize
    const lastPageIndex = firstPageIndex + PageSize
    return blog.slice(firstPageIndex, lastPageIndex)
  }, [currentPage, blog])

  const onChangeClickPage = (page) => {
    setCurrentPage(page)
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  return (
    <>
      <section className="blog-area pt-120 pb-80">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              {currentTableData.map((item, i) => {
                return <BlogFirstArticle blog={item} i={i} />;
              })}
              <Pagination
              className='pagination-bar'
              currentPage={currentPage}
              totalCount={blog.length}
              pageSize={PageSize}
              onPageChange={(page) => onChangeClickPage(page)}
            />
            </div>
            <BlogRightSide blogData={blog} />
          </div>
        </div>
      </section>
    </>
  );
};

export default BlogsArea;
