import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import 'react-quill/dist/quill.snow.css';
import "./assets/scss/theme.scss";
import "./assets/globals.css";
import "./assets/tevily.css";
import "./assets/tevily-responsive.css";
import './assets/admin.css'
import "./index.scss";
import 'react-toastify/dist/ReactToastify.css';
import "react-image-lightbox/style.css";
import "bootstrap/dist/js/bootstrap.min.js";
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import i18n from "./translation/i18n";
import { I18nextProvider } from "react-i18next";
import { HelmetProvider } from "react-helmet-async";

ReactDOM.render(
  <React.Fragment>
    <HelmetProvider>
      <I18nextProvider i18n={i18n}>
        <App />
      </I18nextProvider>
    </HelmetProvider>
  </React.Fragment>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
