import React, { useEffect, useState } from "react";
import Preloader from "../Preloader/Preloader";
import Header from "../Dashboard/Header";
import Sidebar from "../Dashboard/Sidebar";


const LayoutDashboard = ({ children}) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 500);

    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <>
      <Preloader loading={loading} />
        <div style={{ opacity: loading ? 0 : 1 }} className="page-wrapper">
              <div id="layout-wrapper">
                  <Header />
                  <Sidebar />
                  <div className="main-content">{children}</div>
              </div>
        </div>
    </>
  );
};

export default LayoutDashboard;
