import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {FaFacebookF, FaTwitter, FaLinkedinIn, FaYoutube} from "react-icons/fa";
import { Image, Tab, Tabs } from "react-bootstrap";

const ShopDetailsBanner = ({ product }) => {
  const { t } = useTranslation();
  const navigation = useNavigate();
  return (
    <>
      <section className="shop-banner-area pt-120 pb-90">
        <div className="container">
          <div className="row">
            <div className="col-xl-7">
            <div className="shop-thumb-tab mb-30">
              <ul className="nav" id="myTab2" role="tablist">
                {product.imageslide !== undefined ? product.imageslide.map((item, index) => {
                  return <li className="nav-item w-100">
                    <a
                      className={`tnav-link ${index === 0 ? "active" : ""} `}
                      id={`#image${index}`}
                      data-bs-toggle="tab"
                      href={`#image${index}`}
                      role="tab"
                      aria-selected="true"
                    >
                      <img src={item} alt="" />
                    </a>
                  </li>
                }) : <li className="nav-item w-100">
                <a
                  className={`tnav-link active} `}
                  id={`#image${0}`}
                  data-bs-toggle="tab"
                  href={`#image${0}`}
                  role="tab"
                  aria-selected="true"
                >
                  <img src={product.image} alt="" />
                </a>
              </li> }
                </ul>
              </div>
              <div className="product-details-img mb-30">
                <div className="tab-content" id="myTabContent2">
                {product.imageslide !== undefined ? product.imageslide.map((item, index) => {
                  return  <div
                    className={`tab-pane fade ${index === 0 ? " show active" : ""} `}
                    id={`image${index}`}
                    role="tabpanel"
                    key={index}
                  >
                    <div className="product-large-img">
                      <img src={item} alt="" />
                    </div>
                  </div>
                  }) : <div
                  className={`tab-pane fade show active`}
                  id={`image${0}`}
                  role="tabpanel"
                  key={0}
                >
                  <div className="product-large-img">
                    <img src={product.image} alt="" />
                  </div>
                </div>}
                </div>
              </div>
            </div>
            <div className="col-xl-5">
              <div className="product-details mb-30">
                <div className="product-details-title">
                  <h1>{product.title}</h1>
                  <div className="price details-price pb-30 mb-20">
                    <span className="text-danger">
                      {product.price ? product.price : t("header.contact")}
                    </span>
                  </div>
                </div>
                <p>
                  {t("product.producer")}: <b>{product.producer}</b>
                </p>
                <p>
                  {t("product.origin")}: <b>{product.origin}</b>
                </p>
                <p>
                  {t("header.category")}: <b>{product.categoryTitle}</b>
                </p>
                <div className="product-social mb-45">
                  <a href="https://www.facebook.com/profile.php?id=100089830737464&mibextid=LQQJ4d" target="_blank">
                    <FaFacebookF />
                  </a>
                  <a href="#">
                    <FaTwitter />
                  </a>
                  <a href="#">
                    <FaLinkedinIn />
                  </a>
                  <a href="#">
                    <FaYoutube />
                  </a>
                </div>
                <div className="product-details-action">
                  <form action="#">
                    {/* <div className="plus-minus">
                      <div className="cart-plus-minus">
                        <input type="text" defaultValue="1" />
                      </div>
                    </div> */}
                    <button
                      className="primary_btn btn-black"
                      type="button"
                      onClick={() => navigation("/contact")}
                    >
                      {t("header.contact")}
                    </button>
                    {/*<button className="action-btn" type="submit">
                      <i className="fas fa-heart"></i>
                  </button>*/}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ShopDetailsBanner;
