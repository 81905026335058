import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { FaHeart, FaExpand} from "react-icons/fa";

const SingleShop = ({ image, title, data, styleCol, styleTiltle }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className={`${styleCol}`}>
        <div className="product mb-30 border">
          <div className="product__img" style={{ height: "200px" }}>
            <Link to={`/product/${data.id}`}>
              <img src={image} alt="" />
            </Link>
            <div className="product-action text-center">
              <Link to={`/product/${data.id}`}>
                <i className='bx bx-right-arrow-alt'></i>
              </Link>
              {/* <Link to={`/product/${data.id}`}>
                <FaExpand />
              </Link> */}
            </div>
          </div>
          <div className="product__content text-center pt-30">
            <h4 className={`pro-title ${styleTiltle}`}>
              <Link to={`/product/${data.id}`}>{title}</Link>
            </h4>
            <p>
              {t("product.producer")}: <b>{data.producer}</b>
            </p>
            <div className="price">
              <Link className="primary_btn btn-theme" to="/contact">
                {data.price ? "add to cart" : t("header.contact")}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleShop;
