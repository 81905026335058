import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import addContactRt from "../../../Firebase/firestore/addContact";
import { toast } from "react-toastify";

const ContactFormArea = () => {
  const { t } = useTranslation();
  const [note, setNote] = useState('')
  const [phone, setPhone] = useState('')
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  
  const data = {
    id: phone+'-'+ Date.now(),
    name: name,
    phone: phone,
    email: email,
    date: Date.now(),
    note: note,
    status: "chua-xac-nhan"
}


const handleForm = () => {
  addContactRt(data)
  .then(()=>{
    toast('Liên hệ Thành Công! Chúng tôi sẽ liên hệ với bạn. Thanks You!!!', { hideProgressBar: true, autoClose: 10000, type: 'success' })
    setName('')
    setPhone('')
    setEmail('')
    setNote('')
    window.scrollTo({ top: 0, behavior: 'smooth' })
  })
  .catch(err => console.log(err)) 
}
  return (
    <>
      <section className="contact-form-area gray-bg pt-100 pb-100">
        <div className="container">
          <div className="form-wrapper">
            <div className="row align-items-center">
              <div className="col-xl-8 col-lg-8">
                <div className="section-title mb-55">
                  <p>
                    <span></span> {t("contact.contact")}
                  </p>
                  <h1>{t("contact.contact")}</h1>
                </div>
              </div>
            </div>
            <div className="contact-form">
              <form id="contact-form" action="#">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-box user-icon mb-30">
                      <input
                        type="text"
                        name="name"
                        onChange={(e) => setName(e.target.value)} value={name}
                        placeholder={t("contact.fullname")}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-box email-icon mb-30">
                      <input
                        type="text"
                        name="email"
                        onChange={(e) => setEmail(e.target.value)} value={email}
                        placeholder={t("contact.fullemail")}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-box phone-icon mb-30">
                      <input
                        type="text"
                        name="phone"
                        onChange={(e) => setPhone(e.target.value)} value={phone}
                        placeholder={t("contact.fullphone")}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-box message-icon mb-30">
                      <textarea
                        name="message"
                        id="message"
                        cols="30"
                        rows="10"
                        onChange={(e) => setNote(e.target.value)} value={note}
                        placeholder={t("contact.message")}
                      ></textarea>
                    </div>
                    <div className="contact-btn text-center">
                      <button
                        className="primary_btn btn-icon ml-0"
                        type="button"
                        onClick={handleForm}
                      >
                        <span>+</span> {t("contact.send")}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
              <p className="ajax-response text-center"></p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactFormArea;
