import React from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import CommonPageHeader from "../../components/CommonPageHeader/CommonPageHeader";
import ScrollTotop from "../../components/ScrollToTop";
import HomeFooter from "../Home/Home/HomeFooter/HomeFooter";
import HomeHeader from "../Home/Home/HomeHeader/HomeHeader";
import ContactArea from "./ContactArea/ContactArea";
import ContactFormArea from "./ContactFormArea/ContactFormArea";
import ContactMap from "./ContactMap/ContactMap";

const Contact = () => {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>{t("contact.title")}</title>
        <link rel="canonical" href="https://www.tacobell.com/" />
        <meta property="og:title" content={t("contact.title")} />
      </Helmet>
      <ScrollTotop />
      <HomeHeader />
      <CommonPageHeader
        title={t("contact.contact")}
        subtitle={t("contact.contact")}
      />
      <ContactArea />
      <ContactFormArea />
      <ContactMap />
      <HomeFooter />
    </>
  );
};

export default Contact;
