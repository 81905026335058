import React from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import CommonPageHeader from "../../components/CommonPageHeader/CommonPageHeader";
import ScrollTotop from "../../components/ScrollToTop";
import HomeFooter from "../Home/Home/HomeFooter/HomeFooter";
// import HomeFact from "../Home/Home/HomeFact/HomeFact";
import HomeHeader from "../Home/Home/HomeHeader/HomeHeader";
// import HomeOurTeam from "../Home/Home/HomeOurTeam/HomeOurTeam";
// import AboutAnalysis from "./AboutAnalysis/AboutAnalysis";
import AboutAppoinment from "./AboutAppoinment/AboutAppoinment";
import AboutArea from "./AboutArea/AboutArea";
import AboutCounter from "./AboutCounter/AboutCounter";
// import AboutTestimonial from "./AboutTestimonial/AboutTestimonial";

const AboutUs = () => {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>{t("aboutus.title")}</title>
        <link rel="canonical" href="https://www.tacobell.com/" />
        <meta property="og:title" content={t("aboutus.title")} />
      </Helmet>
      <ScrollTotop />
      <HomeHeader />
      <CommonPageHeader
        title={t("header.about")}
        subtitle={t("header.about")}
      />
      <AboutArea />
      <AboutCounter />
      <AboutAppoinment />
      {/* <HomeOurTeam/>
         <HomeFact/> */}
      {/* <AboutTestimonial /> */}
      {/* <AboutAnalysis /> */}
      <HomeFooter />
    </>
  );
};

export default AboutUs;
