import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Preloader from "../Preloader/Preloader";
import ScrollTotop from "../ScrollToTop";
import HomeHeader from "../../pages/Home/Home/HomeHeader/HomeHeader";
import HomeFooter from "../../pages/Home/Home/HomeFooter/HomeFooter";

const Layout = ({ children, pageTitle, pageImage, seotitle, seokey, seodesc}) => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
        setLoading(false);
        }, 500);

        return () => clearTimeout(timeoutId);
    }, []);

    return (
        <>
        <Helmet>
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <title>{pageTitle} || Phu Nguyen || Chuyên cung cấp các thiết bị, vật tư y tế độc quyền chính thức giá rẻ cho khách hàng và người sử dụng. Giành được nhiều sự tin tưởng từ khách hàng thông qua việc tư vấn, cung cấp hệ thống trang thiết bị cho đông đảo các cơ quan, viện, sở, bệnh viện toàn quốc với dịch vụ chuyên nghiệp và chất lượng cao, phù hợp từng  mục đích và yêu cầu sử dụng.</title>
            <meta property="og:locale" content="vi_VN"></meta>
            <meta name="description" content={`${seodesc ? seodesc : "Chuyên cung cấp các thiết bị, vật tư y tế độc quyền chính thức giá rẻ cho khách hàng và người sử dụng. Giành được nhiều sự tin tưởng từ khách hàng thông qua việc tư vấn, cung cấp hệ thống trang thiết bị cho đông đảo các cơ quan, viện, sở, bệnh viện toàn quốc với dịch vụ chuyên nghiệp và chất lượng cao, phù hợp từng  mục đích và yêu cầu  sử dụng."}`} />
            <meta name="keywords" content={`${seokey ? seokey : "Thiết bị ý tế, vật tư ý tế độc quyền, thiết bị cho bệnh viện, viện, sở ..."}`} />
            <link rel='canonical' href={`/`} />
            {/* Facebook meta tags below */}
            <meta property="og:type" content="website" />
            <meta property="og:url" content={`http://phunguyen.vn/`} />
            <meta property="og:title" content={pageTitle} />
            <meta
            property="og:description"
            content={`${seodesc ? seodesc : "Chuyên cung cấp các thiết bị, vật tư y tế độc quyền chính thức giá rẻ cho khách hàng và người sử dụng. Giành được nhiều sự tin tưởng từ khách hàng thông qua việc tư vấn, cung cấp hệ thống trang thiết bị cho đông đảo các cơ quan, viện, sở, bệnh viện toàn quốc với dịch vụ chuyên nghiệp và chất lượng cao, phù hợp từng  mục đích và yêu cầu sử dụng."}`}
            />
            <meta
            property="og:image"
            content={pageImage}
            />
            <meta property="og:image" content={pageImage}></meta>
            <meta property="og:image:width" content="200px"></meta>
            <meta property="og:image:height" content="200px"></meta>
            <meta property="og:image:alt" content={seotitle}></meta>
            {/* <meta property="og:image:type" content="image/jpeg"></meta> */}
            {/* Twitter meta tags below */}
            <meta name="twitter:card" content="book" />
            <meta
            name="twitter:image"
            content={pageImage}
            />
            <meta
            name="twitter:title"
            content={`${pageTitle} || Chuyên cung cấp các thiết bị, vật tư y tế độc quyền chính thức giá rẻ cho khách hàng và người sử dụng. Giành được nhiều sự tin tưởng từ khách hàng thông qua việc tư vấn, cung cấp hệ thống trang thiết bị cho đông đảo các cơ quan, viện, sở, bệnh viện toàn quốc với dịch vụ chuyên nghiệp và chất lượng cao, phù hợp từng  mục đích và yêu cầu sử dụng.`}
            />
            <meta name="twitter:creator" content="@phunguyen.vn" />
            <meta name="twitter:site" content="@phunguyen.vn" />
            <meta
            name="twitter:description"
            content={`${seodesc ? seodesc : "Chuyên cung cấp các thiết bị, vật tư y tế độc quyền chính thức giá rẻ cho khách hàng và người sử dụng. Giành được nhiều sự tin tưởng từ khách hàng thông qua việc tư vấn, cung cấp hệ thống trang thiết bị cho đông đảo các cơ quan, viện, sở, bệnh viện toàn quốc với dịch vụ chuyên nghiệp và chất lượng cao, phù hợp từng  mục đích và yêu cầu sử dụng."}`}
            />
        </Helmet>
        <Preloader loading={loading} />
        <main style={{ opacity: loading ? 0 : 1 }} >
            <ScrollTotop />
            <HomeHeader />
            <div>{children}</div>
            <HomeFooter />
        </main>
        </>
    );
    };

export default Layout;
