import React from "react";
import BlogDetailsLeftSide from "../../../components/BlogDetailsLeftSide/BlogDetailsLeftSide";
import BlogDetailsRightSide from "../../../components/BlogDetailsRightSide/BlogDetailsRightSide";

const BlogDetailsArea = ({ blog, blogData }) => {
  return (
    <>
      <section className="blog-area pt-120 pb-80">
        <div className="container">
          <div className="row">
            <BlogDetailsLeftSide
              blog={blog}
            />
            <BlogDetailsRightSide blogData={blogData} />
          </div>
        </div>
      </section>
    </>
  );
};

export default BlogDetailsArea;
