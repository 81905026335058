import React from "react";
import { Link } from "react-router-dom";
import {FaRegCalendarCheck, FaUser} from "react-icons/fa";

const BlogDetailsLeftSide = ({ details_topBar, blog }) => {
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  const formattedDate = new Intl.DateTimeFormat('en-US', options).format(blog.date);
  return (
    <>
      <div className="col-lg-8">
        <article className="postbox post format-image mb-40">
          {details_topBar}
          <div className="postbox__text bg-none">
            <div className="post-meta mb-15">
              <span>
                <FaRegCalendarCheck className="mtup-5px"/> {formattedDate}
              </span>
              <span>
                <Link to="#">
                  <FaUser className="mtup-5px"/> {" "}
                  {blog.author}
                </Link>
              </span>
            </div>
            <h3 className="blog-title">{blog.title}</h3>
            <div className="post-text mb-20">
              <div
                className="post-text-blog"
                dangerouslySetInnerHTML={{
                  __html: blog.description,
                }}
              ></div>
            </div>
           </div>
        </article>
      </div>
    </>
  );
};

export default BlogDetailsLeftSide;
