import React from "react";
import { Offcanvas } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import Collapsible from "react-collapsible";
import { useTranslation } from "react-i18next";

const Sidebar = ({ show, handleClose }) => {
  const { t, i18n } = useTranslation();

  const Home = <NavLink to="/home">{t("header.home")}</NavLink>;
  const Shop = <NavLink to="/shop?product=0&producer=all">{t("header.product")}</NavLink>;
  const Doctors = <NavLink to="/about">{t("header.about")}</NavLink>;
  const News = <NavLink to="/contact">{t("header.contact")}</NavLink>;
  const Blog = <NavLink to="/blogs">{t("header.news")}</NavLink>;
  const Transfer = <span>{t("transfer")}</span>;
  return (
    <>
      <div>
        <Offcanvas
          show={show}
          onHide={handleClose}
          placement="end"
          className="side__bar"
        >
          <Offcanvas.Header closeButton>
            {/* <Offcanvas.Title>Offcanvas</Offcanvas.Title> */}
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Collapsible
              trigger={Home}
              triggerTagName="div"
              open={false}
            ></Collapsible>

            <Collapsible
              trigger={Shop}
              triggerTagName="div"
              open={false}
            ></Collapsible>

            <Collapsible
              trigger={Doctors}
              triggerTagName="div"
              open={false}
            ></Collapsible>

            <Collapsible
              trigger={News}
              triggerTagName="div"
              open={false}
            ></Collapsible>

            <Collapsible
              trigger={Blog}
              triggerTagName="div"
              open={false}
            ></Collapsible>
            <Collapsible
              trigger={Transfer}
              triggerTagName="div"
              triggerOpenedClassName="icon_close"
              triggerClassName="iconAdd"
              open={false}
            >
              <ul className="sidebar_sub_menu text-white mt-3">
                <li onClick={() => i18n.changeLanguage("vi")}>Việt Nam</li>
                <li onClick={() => i18n.changeLanguage("us")}>English</li>
              </ul>
            </Collapsible>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </>
  );
};

export default Sidebar;
