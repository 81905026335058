import React from "react";
import { useTranslation } from "react-i18next";
import AboutSingleCounter from "../../../components/AboutSingleCounter/AboutSingleCounter";

const AboutCounter = () => {
  const { t } = useTranslation();
  return (
    <>
      <section className="counter-wraper pt-120 pb-90 gray-bg">
        <div className="container">
          <div className="row">
            <AboutSingleCounter
              icon="1"
              counter="68"
              title={t("aboutus.counter.worldwide")}
              content=""
            />
            <AboutSingleCounter
              icon="2"
              counter="132"
              title={t("aboutus.counter.hospital")}
              content=""
            />
            <AboutSingleCounter
              icon="3"
              counter="45"
              title={t("aboutus.counter.local")}
              content=""
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutCounter;
