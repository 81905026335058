import React, { useEffect, useState } from "react";
// import ShopBanner from "../../ShopPage/ShopPage/ShopBanner/ShopBanner";
// import HomeBlogs from "./HomeBlogs/HomeBlogs";
// import HomeCta from "./HomeCta/HomeCta";
// import HomeFact from "./HomeFact/HomeFact";
// import HomeFooter from "./HomeFooter/HomeFooter";
// import HomeOurTeam from "./HomeOurTeam/HomeOurTeam";
// import HomePricing from "./HomePricing/HomePricing";
// import HomeServices from "./HomeServices/HomeServices";
import HomeAboutArea from "./HomeAboutArea/HomeAboutArea";
import HomeHeroSection from "./HomeHeroSection/HomeHeroSection";
import ShopCategory from "./ShopCategory/ShopCategory";
import { useTranslation } from "react-i18next";
import { getDatabase, ref, child, get } from "firebase/database";
import Layout from "../../../components/Layout/Layout";

const Home = () => {
  const { t, i18n } = useTranslation();
  const [data, setData] = useState(null)
    
    useEffect(() => {
    const dbRef = ref(getDatabase());
    get(child(dbRef, `product/${i18n.language}`)).then((snapshot) => {
        if (snapshot.exists()) {
        const data = snapshot.val();
        setData(Object.values(data))
        } else {
        console.log("No data available");
        }
    }).catch((error) => {
        console.error(error);
    })
    },[i18n.language])
    
  return (
    <>
      <Layout pageTitle={t("homepage.title")} >
        <HomeHeroSection />
        <HomeAboutArea />
        {data && <ShopCategory category="1" data={data} />}
        {data && <ShopCategory category="2" data={data}/>}
        {data && <ShopCategory category="3" data={data}/>}
        {data && <ShopCategory category="4" data={data}/>}
        {data && <ShopCategory category="5" data={data}/>}
        {data && <ShopCategory category="6" data={data}/>}
      </Layout>
      {/* <ShopBanner /> */}
      {/* <HomeServices/> */}
      {/* <HomeOurTeam/> */}
      {/* <HomeFact/> */}
      {/* <HomePricing/> */}
      {/* <HomeCta/> */}
      {/* <HomeBlogs /> */}
    </>
  );
};

export default Home;
