import React from "react";
import { useTranslation } from "react-i18next";
import {FaEnvelope, FaMapMarkerAlt, FaPhone} from "react-icons/fa";

const ContactArea = () => {
  const { t } = useTranslation();
  return (
    <>
      <section
        className="contact-area pt-120 pb-90"
        data-background="assets/img/bg/bg-map.png"
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4">
              <div className="contact text-center mb-30 h-100">
              <div className="style-box-svg">
                <div className="style-svg">
                  <FaEnvelope />
                </div>
              </div>
              
                
                <h3>{t("contact.mailhere")}</h3>
                <p><a href="mailto: qd.chu@phunguyen.vn">{t("contact.email")}</a></p>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4">
              <div className="contact text-center mb-30 h-100">
                <div className="style-box-svg">
                  <div className="style-svg">
                    <FaMapMarkerAlt />
                  </div>
                </div>
                <h3>{t("contact.visithere")}</h3>
                <p className="text-left">Trụ sở: Số 3A/43 Gốc Đề, Đường Minh Khai, Phường Hoàng Văn Thụ, Quận Hoàng Mai, Thành phố Hà Nội, Việt Nam</p>
                <p className="text-left">{t("contact.live")}</p>
                <p className="text-left">{t("contact.live2")}</p>
              </div>
            </div>
            <div className="col-xl-4  col-lg-4 col-md-4 ">
              <div className="contact text-center mb-30 h-100">
                <div className="style-box-svg">
                    <div className="style-svg">
                      <FaPhone />
                    </div>
                  </div>
                
                <h3>{t("contact.callhere")}</h3>
                <p>Hotline: {t("contact.phone")}</p>
                <p>Tel: {t("contact.hotline")}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactArea;
