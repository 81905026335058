import React from "react";

const ContactMap = () => {
  return (
    <>
      <section className="map-area">
        <div style={{ width: "100%" }}>
          <iframe
            title="Contact"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3725.9909097430827!2d105.84937851533131!3d20.95288019573929!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3135adb62f05a10f%3A0x4eb4b279737113d8!2zVOG7qSBIaeG7h3AgUGxhemE!5e0!3m2!1svi!2s!4v1677132701587!5m2!1svi!2s"
            style={{ width: "100%" }}
            height={600}
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </div>
      </section>
    </>
  );
};

export default ContactMap;
