import React from "react";
import { Button, Image } from "react-bootstrap";
import { ref, remove } from "firebase/database";
import { toast } from "react-toastify";
import { db } from "../../Firebase/Firebase.config";
import { Link } from "react-router-dom";

const SingleNewOneAdmin = ({ news = {}, newsTwo = false }) => {
const { image, title, author, date, id } = news;

// const navigate = useNavigate()

const options = { year: 'numeric', month: 'long', day: 'numeric' };
const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);

const handleRemoveBlog = () => {
    remove(ref(db, `blogs/vi/` + news.id)).then(() => {
        remove(ref(db, `blogs/us/` + news.id)).then(() => {
            toast('Xóa Blog Thành Công', { hideProgressBar: true, autoClose: 2000, type: 'success' })
            window.location.reload(true);
        })
        .catch((error) => {
            console.log(error)
        })
    })
    .catch((error) => {
        console.log(error)
    })
}
return (
<div
    className={
    newsTwo ? "news-one__single animated fadeInUp" : "news-one__single"
    }
    style={{ userSelect: newsTwo ? "none" : "unset" }}
>
    <div className="news-one__img">
    <Image src={image} alt="" />
    <div className="news-one__date">
        <p>{formattedDate}</p>
    </div>
    </div>
    <div className="news-one__content">
    <ul className="list-unstyled news-one__meta">
        <li>
            {/* <i className='bx bxs-user'></i> */}
            {author} 
        </li>
        {/* <li>
            <i className='bx bx-book-reader'></i>
                Đọc Thêm
        </li> */}
    </ul>
    <h3 className="news-one__title">
        {title}
    </h3>
    <div className="">
        <Link to={`/dashboard/update-blog/${id}`}>
            <Button className="mr-3" variant="warning">Update Blog</Button>
        </Link>
        <Button variant="danger" onClick={handleRemoveBlog} >Xóa Blog</Button>
    </div>
    </div>
    
</div>
);
};

export default SingleNewOneAdmin;
